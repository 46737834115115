/******************************************************************************/
/* Copyright © 2022 bTEND Ltd. All right reserved.                            */
/******************************************************************************/
/******************************************************************************/ 
/*    Author     : Dimitar Krastev at d.krastev@btend.bg                      */
/******************************************************************************/
import * as wb from './index.js';
import * as wbinfo from './wb_texts.js';
import * as wbmenu from './wb_menu.js'
import {normAQdata} from './aqsens.js';
import { Chart, registerables, Interaction } from 'chart.js';
import 'chartjs-adapter-moment';
import moment from 'moment/moment';

import {CrosshairPlugin,Interpolate} from 'chartjs-plugin-crosshair';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables, CrosshairPlugin, annotationPlugin, ChartjsPluginStacked100);
Chart.defaults.font.family = "'FontAwesome', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif ";

Interaction.modes.interpolate = Interpolate;


export var chartWin;
var calcTime;
var chartdata = [];
var chartTemp = [];
var chartHum = [];
var chartBaro = [];
var chartSPL = [];
var chartPM1 = [];
var chartPM25 = [];
var chartPM10 = [];
var dayLabelsBar =[];
var barSPL = [];
var barPM25 = [];
var barGreenWBI = [], barGreenSLI = [];
var barYellowWBI = [], barYellowSLI = [];
var barOrangeWBI = [], barOrangeSLI = [];
var barRedWBI = [], barRedSLI = [];
var barMagentaWBI = [], barMagentaSLI = [];
var piePM25 = [0,0,0,0,0];
var pieSPL = [0,0,0,0,0];
var maxPM10;
var pieDuration;
var allChartData = [chartTemp, chartHum, chartBaro, chartSPL, chartPM1, 
                    chartPM25, chartPM10, dayLabelsBar,barSPL,barPM25,
                    barGreenWBI,barGreenSLI,barYellowWBI,barYellowSLI,
                    barOrangeWBI,barOrangeSLI,barRedWBI,barRedSLI,
                    barMagentaWBI,barMagentaSLI ];
var um25_db60 =0;
var um30_db80 =1;
var um50_db90 =2;
var um75_db110 =3;
var um800_db130 =4;

var barColors = {
    green: '#2eb72b', 
    yellow: '#e9e838',
    orange: '#e16527', 
    red: '#dd1f1d', 
    magenta: '#84223a'
};
var pieTitle = '';
var barTitle = '';
var pieLabelsPM = ['25 µg/m3', '30 µg/m3', '50 µg/m3', '75 µg/m3', '800 µg/m3'];
var pieLabelsDB = ['60 dB', '80 dB', '90 dB', '110 dB', '130 dB'];
/******************************************************************************/ 
/*  Build chart window                                                        */
/******************************************************************************/
function buildChartWindow(){
    var viewH = window.innerHeight;
    var viewW = window.innerWidth;
    var dialogH,dialogW;
    var anchT,anchL;
    var dialogOpts ={
        size:[dialogW,dialogH],
        anchor:[anchT,anchL],
        position: 'topleft',
        initOpen: true
    };
    if (L.Browser.mobile) {
       dialogW = viewW-10;
       dialogH = viewH-10;
       anchT=5;
       anchL=5;
    }else{
    dialogW = viewW * 0.85;
    dialogH =viewH * 0.85;
    anchT =(viewH-dialogH)/2;
    anchL =(viewW-dialogW)/2;
    }
    dialogOpts.size = [dialogW,dialogH];
    dialogOpts.anchor = [anchT,anchL];
    wb.map.closePopup();
    wbmenu.closeMenu();
    chartWin = L.control.dialog(dialogOpts)
            .addTo(wb.map).open(); 
    chartWin.freeze();
    document.getElementsByClassName('leaflet-control-container')[0]
            .appendChild(document.getElementsByClassName('leaflet-control-dialog leaflet-control')[0]);
    document.getElementById('menu_bt').style.display = "none";
    document.getElementById('shop_bt').style.display = "none";
}
function buildChartCanvas(type){
    var canvas1id,canvas2id,canvas3id;
    var legend;
    var canvases;
    var canvasW,canvasH,offsetH=0;
    if (wb.lng === 'BG')
        legend = wbinfo.chartDescription.bg;
    else
        legend = wbinfo.chartDescription.en;
    if (type === 'analyze'){
        canvas1id = 'byweekWBI';
        canvas2id = 'byweekSLI';
        canvas3id = 'byday'
        if(chartWin.options.size[0]>chartWin.options.size[1]){
            //landscape
            canvasW = (chartWin.options.size[0]-60)*0.33;
            canvasH = ((chartWin.options.size[1]-80)/2);
            offsetH = canvasH/4;
            canvases = (
                    '<canvas id="'+canvas1id+'" width="'+ canvasW +'" height="'+ (canvasH - offsetH) +'"></canvas>'+
                    '<canvas id="'+canvas2id+'" width="'+ canvasW +'" height="'+ (canvasH - offsetH) +'"></canvas>'+
                    '<div class = "legendChL" id="legendCh" >'+legend+'</div>'+
                    '<canvas id="'+canvas3id+'" width="'+ (canvasW*2) +'" height="'+ (canvasH + offsetH) +'" ></canvas>'
            );
            chartWin.setContent('<div class = "wbCharts" id = "chartWin" style = "grid-column: 3;">'+canvases+'</div>');
        } else {
            //portrait
            canvasW = (chartWin.options.size[0]-40)/2;
            canvasH = ((chartWin.options.size[1]-80)/3);
            offsetH = canvasH/3;
            canvases = (
                    '<canvas id="'+canvas1id+'" width="'+ canvasW +'" height="'+ (canvasH - offsetH) +'"></canvas>'+
                    '<canvas id="'+canvas2id+'" width="'+ canvasW +'" height="'+ (canvasH - offsetH) +'"></canvas>'+
                    '<canvas id="'+canvas3id+'" width="'+ (chartWin.options.size[0]-40) +'" height="'+ (canvasH + offsetH) +'" ></canvas>'+
                    '<div class = "legendChP" id="legendCh" >'+legend+'</div>'
            );
            chartWin.setContent('<div class = "wbCharts" id = "chartWin" style = "grid-column: 2;">'+canvases+'</div>');
        }
    };
    if (type === 'history'){
        canvas1id = 'pmdb';
        canvas2id = 'thb';
        legend = '';
        canvasW = chartWin.options.size[0]-40;
        canvasH = ((chartWin.options.size[1]-80)/2);
        canvases = (
                    '<canvas id="'+canvas1id+'" width="'+ canvasW +'" height="'+ canvasH +'"></canvas>'+
                    '<canvas id="'+canvas2id+'" width="'+ canvasW +'" height="'+ canvasH +'"></canvas>'
        );
        chartWin.setContent('<div class = "wbCharts" id = "chartWin" style = "grid-column: 1;">'+canvases+'</div>');
    };
    
    
}
export function closeChart(){
    if(chartWin){
        chartWin.destroy();
        chartWin=null;
        document.getElementById('menu_bt').style.display = "block";
        document.getElementById('shop_bt').style.display = "block";
    }
}
export function buildChart(type,aqid){
    buildChartWindow();
    buildChartCanvas(type);
    createChart(aqid,type);
}
/******************************************************************************/ 
/*  chart builders                                                            */
/******************************************************************************/
function createChart(aqid,type){
    var ask;
    ask = new XMLHttpRequest();
    ask.onreadystatechange = function() {
    if (this.readyState === 4 && this.status === 200) {
        try {
            chartdata.length = 0;
            chartdata = JSON.parse(this.responseText);
            normChartData(type);
            drawChart(type);
            }catch(e) {
                 // error 
            }
    }
  };
  ask.open("GET", "./php/aqsens_historyID.php?aqid="+aqid,true);
  ask.send();
}
function normChartData(type){
    allChartData.forEach(function(e){
        e.length = 0;
    });
    chartdata.forEach(function(data) {
        normAQdata(data,type);
    });
    switch (type){
        case 'history':
            chartdata.forEach(function(data) {
                chartTemp.push({x:data.time,y:data.Temperature});
                chartHum.push({x:data.time,y:data.Humidity});
                chartBaro.push({x:data.time,y:data.Barometer});
                chartSPL.push({x:data.time,y:data.SPL});
                chartPM1.push({x:data.time,y:data.PM1A});
                chartPM25.push({x:data.time,y:data.PM2_5A});
                chartPM10.push({x:data.time,y:data.PM10A});
            });
            maxPM10 = Math.max.apply(Math, chartPM10.map(function(o) { return o.y; }));
            break;
        case 'analyze':
            piePM25.forEach(function(e,i,array){
                array[i] = 0;
            });
            pieSPL.forEach(function(e,i,array){
                array[i] = 0;
            });
            pieDuration=0;
            dayLabelsBar.push(moment(chartdata[0].time,'YYYY-MM-DD',false).format('DD.MM.YYYY').toString());
            chartdata.forEach(function(data) {
                barPM25.push({x:moment(data.time,'YYYY-MM-DD',false).format('DD.MM.YYYY').toString(),PM2_5A:data.PM2_5A});
                barSPL.push({x:moment(data.time,'YYYY-MM-DD',false).format('DD.MM.YYYY').toString(),SPL:data.SPL});
                if (dayLabelsBar[dayLabelsBar.length-1]!== moment(data.time,'YYYY-MM-DD',false).format('DD.MM.YYYY').toString())
                    dayLabelsBar.push(moment(data.time,'YYYY-MM-DD',false).format('DD.MM.YYYY').toString());
                if(data.PM2_5A <= 25)
                    piePM25[um25_db60]++;
                else    if(data.PM2_5A <= 30)
                            piePM25[um30_db80]++;
                        else    if(data.PM2_5A <= 50)
                                    piePM25[um50_db90]++;
                                else    if(data.PM2_5A <= 75)
                                            piePM25[um75_db110]++;
                                        else    if(data.PM2_5A > 75)
                                                    piePM25[um800_db130]++;
                if(data.SPL <= 60)
                    pieSPL[um25_db60]++;
                else    if(data.SPL <= 80)
                            pieSPL[um30_db80]++;
                        else    if(data.SPL <= 90)
                                    pieSPL[um50_db90]++;
                                else    if(data.SPL <= 110)
                                            pieSPL[um75_db110]++;
                                        else    if(data.SPL > 110)
                                                    pieSPL[um800_db130]++;
            });
            pieTitle = dayLabelsBar[0]+" \uf07e "+dayLabelsBar[dayLabelsBar.length-1];
            piePM25.forEach(function(e) {
                pieDuration+=e;
            });
            piePM25.forEach(function(e,i,array) {
                array[i] = ((array[i]/pieDuration)*100).toFixed(0);
            });
            pieSPL.forEach(function(e,i,array) {
                array[i] = ((array[i]/pieDuration)*100).toFixed(0);
            });
            
            dayLabelsBar.shift();
            dayLabelsBar.pop();
            barTitle = dayLabelsBar[0]+" \uf07e "+dayLabelsBar[dayLabelsBar.length-1];
            
            dayLabelsBar.forEach(function(day){
                barGreenWBI.push(0);
                barYellowWBI.push(0);
                barOrangeWBI.push(0);
                barRedWBI.push(0);
                barMagentaWBI.push(0);
                barGreenSLI.push(0);
                barYellowSLI.push(0);
                barOrangeSLI.push(0);
                barRedSLI.push(0);
                barMagentaSLI.push(0);
            });
            
            dayLabelsBar.forEach(function(day,i){
                barPM25.forEach(function(data){
                    if((data.PM2_5A <= 25)&&(data.x === day))
                        barGreenWBI[i]++;
                    else    if((data.PM2_5A <= 30)&&(data.x === day))
                                barYellowWBI[i]++;
                            else    if((data.PM2_5A <= 50)&&(data.x === day))
                                        barOrangeWBI[i]++;
                                    else    if((data.PM2_5A <= 75)&&(data.x === day))
                                               barRedWBI[i]++;
                                            else    if((data.PM2_5A > 75)&&(data.x === day))
                                                        barMagentaWBI[i]++;
                });
                barSPL.forEach(function(data){
                    if((data.SPL <= 60)&&(data.x === day))
                        barGreenSLI[i]++;
                    else    if((data.SPL <= 80)&&(data.x === day))
                                barYellowSLI[i]++;
                            else    if((data.SPL <= 90)&&(data.x === day))
                                        barOrangeSLI[i]++;
                                    else    if((data.SPL <= 110)&&(data.x === day))
                                                barRedSLI[i]++;
                                            else    if((data.SPL > 110)&&(data.x === day))
                                                        barMagentaSLI[i]++;
                });
            });
            dayLabelsBar.forEach(function(e,i,array){
                array[i]=array[i].substring(0,5);
            });
            break;
        default:
    };
}
var wbXScales = {
        type: 'timeseries',
        bounds: 'data',
        time: {
            unit: 'day',
            displayFormats: {
                day: 'DD/MM'
            },
            tooltipFormat: 'hh:mm:ss DD-MM-YYYY',
            round: 'minute'
        },
        grid: {
            drawTicks: false
        },
        ticks: {
            source: 'auto',
            color: '#445931',
            font: {
                weight: 'bold'
            }
        }
};
function drawChart(type){
    switch (type){
        case 'history':
            const thbchart = new Chart(document.getElementById('thb'),{
                type: 'line',
                data: {
                    datasets: [
                    {
                        label: '\uf2c9 Temperature',
                        fill: false,
                        data: chartTemp,
                        yAxisID: 'yTemp',
                        type: 'line',
                        backgroundColor: 'red',
                        borderColor: 'red',
                        borderWidth: 1,
                        tension: 0.6,
                        radius: 0.1,
                        interpolate: true
                    },{
                        label:  '\uf043 Humidity',
                        fill: false,
                        data: chartHum,
                        yAxisID: 'yHum',
                        type: 'line',
                        backgroundColor: 'blue',
                        borderColor: 'blue',
                        borderWidth: 1,
                        tension: 0.6,
                        radius: 0.1,
                        interpolate: true
                    },{
                        label: 'Air pressure',
                        fill: false,
                        data: chartBaro,
                        yAxisID: 'yBaro',
                        type: 'line',
                        backgroundColor: 'green',
                        borderColor: 'green',
                        borderWidth: 1,
                        tension: 0.6,
                        radius: 0.1,
                        interpolate: true
                    }
                    ]
                },
                options: {
                    locale: 'bg',
                    plugins: {
                        title: {
                            display: false
                        },
                        legend: {
                            labels: {
                                usePointStyle: true,
                                boxWidth: 10,
                                color: '#445931',
                            font: {
                                weight: 'bold',
                                size: 15
                            }
                            }
                        },
                        tooltip: {
                            intersect: false,
                            mode: 'interpolate',
                            backgroundColor: 'rgba(68, 89, 49, 0.8)',
                            callbacks: {
                                title: (a) => {
                                    calcTime = (parseInt(a[0].element.x.toString().substring(0, 10))+parseInt(a[0].element.x.toString().substring(10, 13)));
                                        return moment.unix(calcTime).format("HH:MM:SS DD-MM-YYYY");
                                    },
                                label: (tooltipItems) => {
                                    if (tooltipItems.dataset.label.includes('Temperature'))
                                        return tooltipItems.dataset.label + ': '  + Number(tooltipItems.dataset.interpolatedValue).toFixed(1) + ' \u00B0'+'\u0043';
                                    if (tooltipItems.dataset.label.includes('Humidity'))
                                        return tooltipItems.dataset.label + ': '  + Number(tooltipItems.dataset.interpolatedValue).toFixed(1) + ' %'//' \uf295';
                                    if (tooltipItems.dataset.label.includes('pressure'))
                                        return tooltipItems.dataset.label + ': '  + Number(tooltipItems.dataset.interpolatedValue).toFixed(1) + ' hPa';
                                }
                            }
                        },
                        crosshair: {
                            line: {
                              color: '#F66',  // crosshair line color
                              width: 1,        // crosshair line width
                              dashPattern: [0, 0]
                            },
                            sync: {
                              enabled: true,            // enable trace line syncing with other charts
                              group: 1,                 // chart group
                              suppressTooltips: false   // suppress tooltips when showing a synced tracer
                            },
                            zoom: {
                                enabled: false
                            }
                        },
                        stacked100: { enable: false, replaceTooltipLabel: false }
                    },
                    scales: {
                      x: wbXScales,
                      yTemp: {
                        title: {
                          display: true,
                          text: '\u00B0'+'\u0043',
                          color: 'red',
                          font: {
                                weight: 'bold'
                            }
                        },
                        position: 'left',
                        beginAtZero: true,
                        ticks: {
                            color: 'red',
                            font: {
                                weight: 'bold'
                            }
                        }
                      },
                      yBaro: {
                        title: {
                          display: true,
                          text: 'hPa',
                          color: 'green',
                          font: {
                                weight: 'bold',
                            }
                        },
                        position: 'right',
//                        beginAtZero: true,
                        ticks: {
                            color: 'green',
                            font: {
                                weight: 'bold'
                            }
                        },
//                        suggestedMax: 130
                      },
                      yHum: {
                        title: {
                          display: true,
                          text: '\uf295',
                          color: 'blue',
                          font: {
                                weight: 'bold'
                            }
                        },
                        position: 'right',
                        beginAtZero: true,
                        ticks: {
                            color: 'blue',
                            font: {
                                weight: 'bold'
                            }
                        }
                      }
                    }
                  }
            });
            const pmchart = new Chart(document.getElementById('pmdb'),{
                type: 'line',
                data: {
                    datasets: [
                    { 
                        label: '\uf028 spl',
                        fill: false,
                        data: chartSPL,
                        yAxisID: 'yDB',
                        type: 'line',
                        backgroundColor: 'green',
                        borderColor: 'green',
                        borderWidth: 1,
                        tension: 0.6,
                        radius: 0.1,
                        interpolate: true
                    },{
                        label:  '\uf192 1 µm',
                        fill: true,
                        data: chartPM1,
                        yAxisID: 'yPM',
                        type: 'line',
                        backgroundColor: 'rgba(179, 240, 255, 0.5)',
                        borderColor: 'rgb(0, 163, 204)',
                        borderWidth: 1,
                        tension: 0.6,
                        radius: 0.1,
                        interpolate: true
                    },{
                        label: '\uf192 2.5 µm',
                        fill: '-1',
                        data: chartPM25,
                        yAxisID: 'yPM',
                        type: 'line',
                        backgroundColor: 'rgba(0, 163, 204, 0.5)',
                        borderColor: 'rgb(0, 82, 102)',
                        borderWidth: 1,
                        tension: 0.6,
                        radius: 0.1,
                        interpolate: true
                    },{
                        label: '\uf192 10 µm',
                        fill: '-1',
                        data: chartPM10,
                        yAxisID: 'yPM',
                        type: 'line',
                        backgroundColor: 'rgba(0, 82, 102, 0.5)',
                        borderColor: 'rgb(0, 82, 102)',
                        borderWidth: 1,
                        tension: 0.6,
                        radius: 0.1,
                        interpolate: true
                    }
                    ]
                },
                options: {
                    plugins: {
                        title: {
                            display: false
                        },
                        legend: {
                            labels: {
                                usePointStyle: true,
                                boxWidth: 10,
                                color: '#445931',
                            font: {
                                weight: 'bold',
                                size: 15
                            },
                            filter:  (legendItem, data) => {
                                if((legendItem.text === 'limit 2.5 µm')|| (legendItem.text === 'limit 10 µm'))
                                    return false;
                                else 
                                    return true;
                            }  
                            }
                        },
                        tooltip: {
                            intersect: false,
                            mode: 'interpolate',
                            backgroundColor: 'rgba(68, 89, 49, 0.8)',
                            callbacks: {
                                title: (a) => {
                                    calcTime = (parseInt(a[0].element.x.toString().substring(0, 10))+parseInt(a[0].element.x.toString().substring(10, 13)));
                                        return moment.unix(calcTime).format("HH:MM:SS DD-MM-YYYY");
                                    },
                                label: (tooltipItems) => {
                                    if (tooltipItems.dataset.label.includes('limit'))
                                        return;
                                    if (tooltipItems.dataset.label.includes('spl'))
                                        return tooltipItems.dataset.label + ': ' + Number(tooltipItems.dataset.interpolatedValue).toFixed(1) + ' dB';
                                    if (tooltipItems.dataset.label.includes('µm'))
                                        return tooltipItems.dataset.label + ': ' + Number(tooltipItems.dataset.interpolatedValue).toFixed(1) + ' µg/m3';
                                }
                            }
                        },
                        crosshair: {
                            line: {
                              color: '#F66',  // crosshair line color
                              width: 1,        // crosshair line width
                              dashPattern: [0, 0]
                            },
                            sync: {
                              enabled: true,            // enable trace line syncing with other charts
                              group: 1,                 // chart group
                              suppressTooltips: false   // suppress tooltips when showing a synced tracer
                            },
                            zoom: {
                                enabled: false
                            }
                        },
                        autocolors: false,
                        annotation: {
                            annotations: {
                                limit25: {
                                    type: 'line',
                                    yScaleID: 'yPM',
                                    yMin: 25,
                                    yMax: 25,
                                    borderColor: 'rgb(46, 183, 43)',
                                    borderWidth: 1.5,
                                    borderDash: [5, 5],
                                    label: {
                                        enabled: true,
                                        color:'#445931',
                                        content: '25 µg/m3',
                                        backgroundColor: 'rgba(46, 183, 43, 0.5)',
                                        borderColor: 'rgb(46, 183, 43)',
                                        position: 'start',
                                        yAdjust: -15
                                    }
                                },
                                limit50: {
                                    type: 'line',
                                    yScaleID: 'yPM',
                                    yMin: 50,
                                    yMax: 50,
                                    borderColor: 'rgb(221, 31, 29)',
                                    borderWidth: 1.5,
                                    borderDash: [5, 5],
                                    label: {
                                        enabled: true,
                                        color:'#445931',
                                        content: '50 µg/m3',
                                        backgroundColor: 'rgba(221, 31, 29, 0.5)',
                                        borderColor: 'rgb(221, 31, 29)',
                                        position: 'start',
                                        yAdjust: -15
                                    }
                                }
                            }
                        },
                        stacked100: { enable: false, replaceTooltipLabel: false }
                    }, //end of plugins
                    
                    scales: {
                      x: wbXScales,
                      yPM: {
                        title: {
                          display: true,
                          text: 'µg/m3',
                          font: {
                                weight: 'bold'
                            }
                        },
                        position: 'left',
                        beginAtZero: true,
                        ticks: {
                            font: {
                                weight: 'bold'
                            }
                        }
                      },
                      yDB: {
                        title: {
                          display: true,
                          text: 'dB',
                          color: 'green',
                          font: {
                                weight: 'bold',
                            }
                        },
                        position: 'right',
                        beginAtZero: true,
                        ticks: {
                            color: 'green',
                            font: {
                                weight: 'bold'
                            }
                        },
//                        suggestedMax: 130
                      },
                      yPM10: {
                        title: {
                          display: true,
                          text: 'µg/m3',
                          font: {
                                weight: 'bold'
                            }
                        },
                        position: 'right',
                        beginAtZero: true,
                        suggestedMax: maxPM10,
                        ticks: {
                            font: {
                                weight: 'bold'
                            }
                        }
                      }
                    }
                  }
            });
            break;
        case 'analyze':
            const piePM = new Chart (document.getElementById('byweekWBI'),{
                type:'doughnut',
                data: {
                    labels: pieLabelsPM,
                    datasets: [
                    {
                        label: 'WBI',
                        data: piePM25,
                        backgroundColor: Object.values(barColors),
                        datalabels: {
                            labels: {
                                value: {
                                    align: 'center',
                                    color: 'white',
                                    textStrokeColor: 'black',
                                    textStrokeWidth: 1.5,
                                    font: {
                                        weight: 'bold',
                                        size: '15vh'
                                    },
                                    formatter: function(value) {
                                        if(value !== '0')
                                            return value + ' %';
                                        else 
                                            return '';
                                    },
                                    padding: 4
                                }
                            }
                        }
                    }]
                },
                options: {
                    responsive: false,
                    plugins: {
                        legend: false,
                        title: {
                            display: true,
                            text: pieTitle,
                            font: {
                                weight: 'bold',
                                size: 15
                            }
                        },
                        crosshair: false,
                        stacked100: false,
                        tooltip: false
                    } //end of plugins
                }, // end of options
                plugins: [{
                    beforeDraw: chart => {
//                    console.log(chart);
                    var ctx = chart.ctx;
                    ctx.save();
                    var image = new Image();      
                    image.src = '/res/WBI.svg';      
                    //console.log(chart._metasets[0].controller.innerRadius);
                    var imageSize = chart._metasets[0].controller.innerRadius*2*1.2;
                    ctx.drawImage(image, chart.width / 2 - imageSize / 2, chart.height / 2 - imageSize / 2 + 19, imageSize, imageSize);
                    ctx.restore();
                    }
                },
                ChartDataLabels
                ],
            });
            const pieSLI = new Chart (document.getElementById('byweekSLI'),{
                type:'doughnut',
                data: {
                    labels: pieLabelsPM,
                    datasets: [
                    {
                        label: 'SLI',
                        data: pieSPL,
                        backgroundColor: Object.values(barColors),
                        datalabels: {
                            labels: {
                                value: {
                                    align: 'center',
                                    color: 'white',
                                    textStrokeColor: 'black',
                                    textStrokeWidth: 1.5,
                                    font: {
                                        weight: 'bold',
                                        size: '15vh'
                                    },
                                    formatter: function(value) {
                                        if(value !== '0')
                                            return value + ' %';
                                        else 
                                            return '';
                                    },
                                    padding: 4
                                }
                            }
                        }
                    }]
                },
                options: {
                    responsive: false,
                    hover: {mode: null},
                    plugins: {
                        legend: false,
                        title: {
                            display: true,
                            text: pieTitle,
                            font: {
                                weight: 'bold',
                                size: 15
                            }
                        },
                        crosshair: false,
                        stacked100: false,
                        tooltip: false
                    } //end of plugins
                }, // end of options
                plugins: [{
                    beforeDraw: chart => {
//                    console.log(chart);
                    var ctx = chart.ctx;
                    ctx.save();
                    var image = new Image();      
                    image.src = '/res/SLI.svg';      
                    var imageSize = chart._metasets[0].controller.innerRadius*2*1.2;
                    ctx.drawImage(image, chart.width / 2 - imageSize / 2, chart.height / 2 - imageSize / 2 + 19, imageSize, imageSize);
                    ctx.restore();
                    }
                },
                ChartDataLabels
                ]
            });
            const dayBars = new Chart (document.getElementById('byday'),{
                type:'bar',
                data: {
                    labels: dayLabelsBar,
                    datasets: [
                        {   stack: 'WBI',
                            data: barGreenWBI,
                            backgroundColor: barColors.green,
                        },
                        {   stack: 'WBI',
                            data: barYellowWBI, 
                            backgroundColor: barColors.yellow,
                        },
                        {    stack: 'WBI',
                              data: barOrangeWBI, 
                                backgroundColor: barColors.orange,
                        },
                        {   stack: 'WBI',
                            data: barRedWBI, 
                            backgroundColor: barColors.red,
                        },
                        {   stack: 'WBI',
                            data: barMagentaWBI, 
                            backgroundColor: barColors.magenta,
                        },
                        {   stack: 'SLI', 
                            data: barGreenSLI, 
                            backgroundColor: barColors.green,
                        },
                        {   stack: 'SLI',
                            data: barYellowSLI, 
                            backgroundColor: barColors.yellow,
                        },
                        {   stack: 'SLI',
                            data: barOrangeSLI, 
                            backgroundColor: barColors.orange,
                        },
                        {   stack: 'SLI',
                            data: barRedSLI, 
                            backgroundColor: barColors.red,
                        },
                        {   stack: 'SLI',
                            data: barMagentaSLI, 
                            backgroundColor: barColors.magenta,
                        }
                    ]
                },
                options: {
                    responsive: false,
                    hover: {mode: null},
                    plugins: {
                        tooltip: {enabled: false},
                        legend: false,
                        title: {
                            display: true,
                            text: barTitle,
                            font: {
                                weight: 'bold',
                                size: 15
                            }
                        },
                        crosshair: false,
                        stacked100: { enable: true, replaceTooltipLabel: false },                    
                        datalabels: {
                                labels: {
                                    value: {
                                        align: 'center',
                                        color: 'white',
                                        textStrokeColor: 'black',
                                        textStrokeWidth: 1.5,
                                        font: {
                                            weight: 'bold',
                                            size: '15vh',
                                        },
                                        formatter: function(value) {
                                            if(value !== 0)
                                                return value + ' %';
                                            else 
                                                return '';
                                        },
                                        padding: 4
                                    },
                                    stack: {
                                        align: 'start',
                                        anchor: 'start',
                                        color: '#445931',
                                        font: {
                                            weight: 'bold',
                                            size: '15vh',
                                        },
                                        formatter: function(value, ctx) {
                                            if(ctx.dataset.backgroundColor === barColors.green)
                                                    return ctx.dataset.stack;
                                                else 
                                                    return '';
                                        },
                                        padding: 4
                                    }
                                }
                            }
                    }, //end of option plugins
                    scales: {
                      x: {
                        type: "category",
                        grid: {
                            display: false
                        },
                        ticks: {
                            font: {
                                weight: 'bold',
                                size: 15
                            },
                            padding: 12
                        }
                         
                      },
                      y: {
                        display: false,
                        title: {
                          display: true,
                          text: '%',
                          font: {
                                weight: 'bold'
                            }
                        },
                        position: 'left',
                        beginAtZero: true,
                        ticks: {
                            font: {
                                weight: 'bold'
                            },
                        grid: {
                            display: false
                        }
                        }
                        }
                    }
                }, // end of options
                plugins: [
                ChartDataLabels
                ]
            });
            break;
        default:
    };
}
/*****************************end of aqcharts**********************************/
