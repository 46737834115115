/******************************************************************************/
/* Copyright © 2022 bTEND Ltd. All right reserved.                            */
/******************************************************************************/
/******************************************************************************/ 
/*    Author     : Dimitar Krastev at d.krastev@btend.bg                      */
/******************************************************************************/
import * as wb from './index.js';
import * as wbCtrl from './wb_controls.js';
import * as text from './wb_texts.js';
import * as aqsens from './aqsens.js';

export var menuWin;
export var menuShopWin;
export var subMenuWin;

var menuItems ;

export function closeMenu(){
    if(menuWin){
        menuWin.destroy();
        menuWin=null;
        wbCtrl.menuB.state('menuO');
    }
    if(subMenuWin){
        subMenuWin.destroy();
        subMenuWin=null;
        document.getElementById('shop_bt').style.display = "block";
    }
    if(menuShopWin){
        menuShopWin.destroy();
        menuShopWin=null;
        wbCtrl.shopB.state('shopO');
    }
}
function buildShopMenu(){
    var viewH = window.innerHeight;
    var viewW = window.innerWidth;
    var dialogH,dialogW;
    var anchT,anchL;
    var menuS;
    var dialogOpts ={
        size:[dialogW,dialogH],
        anchor:[anchT,anchL],
        position: 'topleft',
        initOpen: true
    };
    if (L.Browser.mobile){
            dialogW = viewW * 0.85;
            if(viewH>viewW)
                dialogH = viewH * 0.79;
            else
                dialogH = viewH * 0.72;
        } else {
            dialogH = viewH * 0.87;
            if(viewW<=1200)
                dialogW = viewW * 0.40;
            else
                dialogW = viewW * 0.30;
        }
    menuS = document.getElementById('shop_bt').getBoundingClientRect();
//    dialogH = menuS.height;
    anchT = menuS.top+menuS.height/2+5;
    anchL = menuS.left+menuS.width/2;
//    console.log(menuS);
    
    dialogOpts.size = [dialogW,dialogH];
    dialogOpts.anchor = [anchT,anchL];
    wb.map.closePopup();
    menuShopWin = L.control.dialog(dialogOpts)
            .addTo(wb.map).open(); 
    menuShopWin.lock();
    document.getElementsByClassName('leaflet-control-container')[0]
            .appendChild(document.getElementsByClassName('leaflet-control-dialog leaflet-control')[0]);
};
function buildMenu(){
    var viewH = window.innerHeight;
    var viewW = window.innerWidth;
    var dialogH,dialogW;
    var anchT,anchL;
    var menuB;
    var dialogOpts ={
        size:[dialogW,dialogH],
        anchor:[anchT,anchL],
        position: 'topleft',
        initOpen: true
    };
    if (L.Browser.mobile){
        dialogW = viewW * 0.85;
    } else {
        if(viewW<=1200)
            dialogW = viewW * 0.40;
        else
            dialogW = viewW * 0.30;
    }
    
    menuB = document.getElementById('menu_bt').getBoundingClientRect();
    
    dialogH = menuB.height;
    anchT = menuB.top;
    anchL = menuB.left+menuB.width/2;
//    console.log(menuB);
    
    dialogOpts.size = [dialogW,dialogH];
    dialogOpts.anchor = [anchT,anchL];
    wb.map.closePopup();
    menuWin = L.control.dialog(dialogOpts)
            .addTo(wb.map).open(); 
    menuWin.lock();
    document.getElementsByClassName('leaflet-control-container')[0]
            .appendChild(document.getElementsByClassName('leaflet-control-dialog leaflet-control')[0]);
};
function buildSubMenu(){
    var viewH = window.innerHeight;
    var viewW = window.innerWidth;
    var dialogH,dialogW;
    var anchT,anchL;
    var subMenu;
    var dialogOpts ={
        size:[dialogW,dialogH],
        anchor:[anchT,anchL],
        position: 'topleft',
        initOpen: true
    };
    if(!subMenuWin){
        document.getElementById('shop_bt').style.display = "none";
        if (L.Browser.mobile){
            dialogW = viewW * 0.85;
            if(viewH>viewW)
                dialogH = viewH * 0.87;
            else
                dialogH = viewH * 0.82;
        } else {
            dialogH = viewH * 0.92;
            if(viewW<=1200)
                dialogW = viewW * 0.40;
            else
                dialogW = viewW * 0.30;
        }

        subMenu = document.getElementById('menu_bt').getBoundingClientRect();
        anchT = subMenu.top+subMenu.height-2;
        anchL = subMenu.left+subMenu.width/2;

        dialogOpts.size = [dialogW,dialogH];
        dialogOpts.anchor = [anchT,anchL];
        wb.map.closePopup();
        subMenuWin = L.control.dialog(dialogOpts).addTo(wb.map).open(); 
        subMenuWin.lock();
        document.getElementsByClassName('leaflet-control-container')[0]
                .appendChild(document.getElementsByClassName('leaflet-control-dialog leaflet-control')[0]).style.zIndex = '4000';
    }
};
function setMenuItem(){
    const wb = "<button class='subB' id='wb_b' title='WhatBreathe'><img src='/res/WB.svg'></i></button>";
    const aq = "<button class='subB' id='aq_b' title='AQsens'><img src='/res/AQ.svg'></i></button>";
    const set = "<button class='subB' id='set_b' title='Settings'><img src='/res/Settings.svg'></i></button>";
    const reg = "<button class='subB' id='reg_b' title='AQsens registration'><img src='/res/Registered.svg'></i></button>";
    const sh = "<button class='subB' id='sh_b' title='Shop'><img src='/res/Shop2.svg'</button>";
    const sp = "<button class='subB' id='sup_b' title='Support'><img src='/res/Mail.svg'></i></button>";
    const lg_bg = "<button class='lngB' id='lng_b' title='Language' style = 'float: right;'><img id='lng_b_img' src='/res/BG.svg'></i></button>";
    
    menuItems = wb+aq+set+reg+sp+lg_bg;
    menuWin.setContent(menuItems);
}
export function openShopMenu(){
    if(menuWin){
        menuWin.destroy();
        menuWin=null;
        wbCtrl.menuB.state('menuO');
    }
    if(subMenuWin){
        subMenuWin.destroy();
        subMenuWin=null;
        document.getElementById('shop_bt').style.display = "block";
    }
    buildShopMenu();
    if (wb.lng === 'BG')
         menuShopWin.setContent(text.order.bg);
    else
         menuShopWin.setContent(text.order.en);
    document.getElementById('orderOK').addEventListener('click',SendOrder);
    document.querySelectorAll("input")
    .forEach((elem) => elem.addEventListener('keyup', checkOrder));
    document.querySelectorAll("input")
    .forEach((elem) => elem.addEventListener('click', checkOrder));
//    document.querySelectorAll("input[name=regAgreed]")
//    .forEach((elem) => elem.addEventListener("change", checkOrder));
}
export function openMenu(){
    if(menuShopWin){
        menuShopWin.destroy();
        menuShopWin=null;
        wbCtrl.shopB.state('shopO');
    }
    buildMenu();
    document.getElementsByClassName('leaflet-control-dialog-inner')[0].style.paddingTop = '0px';
    document.getElementsByClassName('leaflet-control-dialog-inner')[0].style.paddingBottom = '0px';
    setMenuItem();
    if (wb.lng === 'BG')
         document.getElementById('lng_b_img').src = '/res/EN.svg';
    else
         document.getElementById('lng_b_img').src = '/res/BG.svg';
    //addEventListener-openSubMenu to all subButtons
    if (document.getElementsByClassName('subB')){
        document.querySelectorAll('.subB').forEach(button => {
          button.addEventListener('click', function (e) {
                openSubMenu(this.id);
            });
        });
    };
    document.getElementById('lng_b').addEventListener('click', changeLng);
}
export function openSubMenu(btId){
    buildSubMenu();
    switch (btId){
        case 'wb_b':{
                if (wb.lng === 'BG')
                    subMenuWin.setContent(text.info.bg);
                else
                    subMenuWin.setContent(text.info.en);
                break;
        }
        case 'aq_b':{
                if (wb.lng === 'BG')
                    subMenuWin.setContent(text.description.bg);
                else
                    subMenuWin.setContent(text.description.en);
                break;
        }
        case 'set_b':{
                if (wb.lng === 'BG'){
                    if (L.Browser.mobile)
                        subMenuWin.setContent(text.setupMobile.bg);
                    else
                        subMenuWin.setContent(text.setupPC.bg);
                }else {
                    if (L.Browser.mobile)
                        subMenuWin.setContent(text.setupMobile.en);
                    else
                        subMenuWin.setContent(text.setupPC.en);
                }
                if(document.getElementById('aqpage'))
                    document.getElementById('aqpage').addEventListener('click',function(){(window.open("http://100.100.0.1"));});
                break;
        }
        case 'reg_b':{
                if (wb.lng === 'BG')
                    subMenuWin.setContent(text.register.bg);
                else
                    subMenuWin.setContent(text.register.en);
                    document.getElementById('regok').addEventListener('click',setAQsens);
                     document.querySelectorAll("input")
                        .forEach((elem) => elem.addEventListener('keyup', checRigistration));
                     document.querySelectorAll("input")
                        .forEach((elem) => elem.addEventListener('click', checRigistration));
                       
                break;
        }
        case 'sup_b':{
                if (wb.lng === 'BG')
                    subMenuWin.setContent(text.mailTo.bg);
                else
                    subMenuWin.setContent(text.mailTo.en);
                document.getElementById('sendMess').addEventListener('click',SendMessage);
                document.querySelectorAll("input")
                    .forEach((elem) => elem.addEventListener('keyup', checkMailTo));
                document.querySelectorAll("input")
                    .forEach((elem) => elem.addEventListener('click', checkMailTo));
                document.getElementById('ymes').addEventListener('click',checkMailTo);
                document.getElementById('ymes').addEventListener('keyup',checkMailTo);
                break;
        }
        default:
    }
}

export function changeLng(){
    switch (wb.lng){
        case 'BG':{
                wb.setLng('EN');
                document.getElementById('lng_b_img').src = '/res/BG.svg';
                if (document.getElementById('inf')){document.getElementById('inf').innerHTML=text.info.en;};
                if (document.getElementById('desc')){document.getElementById('desc').innerHTML=text.description.en;};
                if (document.getElementById('stp')){document.getElementById('stp').innerHTML=text.setupPC.en;};
                if (document.getElementById('stp_m')){document.getElementById('stp_m').innerHTML=text.setupMobile.en;};
                if (document.getElementById('divreg')){document.getElementById('divreg').innerHTML=text.register.en;};
                if (document.getElementById('divmail')){document.getElementById('divmail').innerHTML=text.mailTo.en;};  
                break
        }
        case 'EN':{
                wb.setLng('BG');
                document.getElementById('lng_b_img').src = '/res/EN.svg';
                if (document.getElementById('inf')){document.getElementById('inf').innerHTML=text.info.bg;};
                if (document.getElementById('desc')){document.getElementById('desc').innerHTML=text.description.bg;};
                if (document.getElementById('stp')){document.getElementById('stp').innerHTML=text.setupPC.bg;};
                if (document.getElementById('stp_m')){document.getElementById('stp_m').innerHTML=text.setupMobile.bg;};
                if (document.getElementById('divreg')){document.getElementById('divreg').innerHTML=text.register.bg;};
                if (document.getElementById('divmail')){document.getElementById('divmail').innerHTML=text.mailTo.bg;};  
                break
        }
        default:
    }
}

function validateMail(mail){
   const mails = 
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   if (mails.test(mail.value)) {
       mail.style.backgroundColor='#ffffff';
       return true;
   }
   mail.style.backgroundColor='#ffcccc';
   return false;
}
function validateFormField(ffield,length){
    const letters = /^[A-Za-zА-Яа-я_0-9. ]*$/g;
    const aqnum  = /^[A-Z0-9]*$/g;
    if((ffield.id === 'ymes')&&(ffield.value.length>=length)){
        ffield.style.backgroundColor='#ffffff';
       return true;
    }
    else if((aqnum.test(ffield.value))&&(ffield.id === 'aqnum')&&(ffield.value.length>=length)){
        ffield.style.backgroundColor='#ffffff';
        return true;
        }
        else if (letters.test(ffield.value)&&(ffield.value.length>=length)) {
            ffield.style.backgroundColor='#ffffff';
            return true;
            }
             else {
                ffield.style.backgroundColor='#ffcccc';
                return false;
            }
    
}
function validatePhone(phone){
    const numbers = /^\+?\d*$/g;
    if (numbers.test(phone.value)) {
       phone.style.backgroundColor='#ffffff';
       return true;
    }
    phone.style.backgroundColor='#ffcccc';
    return false;
}
function validateAQID(aqid){
    var retdata;
    var ask = new XMLHttpRequest();
    ask.onreadystatechange = function() {
    if (this.readyState === 4 && this.status === 200) {
        
    retdata =JSON.parse(this.responseText);

    if(retdata[0].num>=1){
        document.getElementById("aqmail").disabled=false;
        document.getElementById("aqname").disabled=false;
        document.getElementById("aqfamily").disabled=false;
    }else 
        document.getElementById("aqnum").style.backgroundColor='#ffcccc';
            
    }
    };
    ask.open("GET", "./php/aqsens_checkID.php?aqid="+aqid,true);
    ask.send();
}

function getCID(mail){
  var ask; 
  var retdata;
  
  ask = new XMLHttpRequest();
  ask.onreadystatechange = function() {
    if (this.readyState === 4 && this.status === 200) {
    retdata =JSON.parse(this.responseText);
    newaqsens_data.c_id=retdata[0].c_id;
    //console.log(newaqsens_data);
    }
  };
  ask.open("GET", "./php/check_mail.php?mail="+mail,true);
  ask.send();
}

function checkOrder(){
    if((validateFormField(document.getElementById("cname"),2))
        &&(validateFormField(document.getElementById("cfamily"),2))
        &&(validatePhone(document.getElementById("cphone")))
        &&(validateMail(document.getElementById("cmail")))
        &&(validateFormField(document.getElementById("caddr"),8))
        &&(document.getElementById("orderChk").checked)){
            document.getElementById("orderOK").disabled=false;
        }else{
            document.getElementById("orderOK").disabled=true;
        }
}

function checkMailTo(){
    if((validateFormField(document.getElementById("yname"),2))
        &&(validateFormField(document.getElementById("yfamily"),2))
        &&(validateMail(document.getElementById("ymail")))
        &&(validateFormField(document.getElementById("ymes"),10))
        &&(document.getElementById("messChk").checked)){
            document.getElementById("sendMess").disabled=false;
        }else{
            document.getElementById("sendMess").disabled=true;
        }
}

function checRigistration(){
    if((document.getElementById("aqname").disabled)
        &&(document.getElementById("aqfamily").disabled)
        &&(document.getElementById("aqmail").disabled)
        &&(validateFormField(document.getElementById("aqnum"),14))){
        validateAQID(document.getElementById("aqnum").value);
    } 
     else  {
         var el = document.getElementById("aqmail");
            if(!el.disabled){
                if ((validateMail(document.getElementById("aqmail")))
                       &&(validateFormField(document.getElementById("aqname"),2))
                       &&(validateFormField(document.getElementById("aqfamily"),2))
                       &&(document.getElementById("regChk").checked)){
                           document.getElementById("regok").disabled=false;
                       }else{
                           document.getElementById("regok").disabled=true;
                       }
            }
     }
}

var mailInfo ={
    name:'',
    family:'',
    mail:'',
    mess:'',
    to:''
};
var alertMes;
function SendMessage(){
    mailInfo.name=document.getElementById("yname").value;
    mailInfo.family=document.getElementById("yfamily").value;
    mailInfo.mail=document.getElementById("ymail").value;
    mailInfo.mess=document.getElementById("ymes").value;
    mailInfo.to=document.getElementById("omail").value;
    closeMenu();
    var sendmail = new XMLHttpRequest();
    sendmail.open("GET", "./php/aqsens_sendmess.php?mailInfo="+JSON.stringify(mailInfo),true);
    sendmail.send();
    // 4. This will be called after the response is received
    sendmail.onload = function() {
      if (sendmail.status !== 200) { // analyze HTTP status of the response
        alert(`Error ${sendmail.status}: ${sendmail.statusText}`); // e.g. 404: Not Found
      } else { // show the result
            if (wb.lng === 'BG')
                 alertMes='Съобщението е изпратено!';
            else
                alertMes='Message was sent!';
            alert(alertMes);
      }
    };
    sendmail.onerror = function() {
        if (wb.lng === 'BG')
            alertMes='Възникна грешка, опитайте отново!';
        else
            alertMes='Error ocur, please try again later!';
        alert(alertMes);
    };
}

var orderInfo ={
    address:'',
    name:'',
    family:'',
    mail:'',
    phone:'',
    quantity:''
};

function SendOrder(){
    orderInfo.address=document.getElementById("caddr").value;
    orderInfo.name=document.getElementById("cname").value;
    orderInfo.family=document.getElementById("cfamily").value;
    orderInfo.phone=document.getElementById("cphone").value;
    orderInfo.mail=document.getElementById("cmail").value;
    orderInfo.quantity=1;
    closeMenu();
    var sendmail = new XMLHttpRequest();
    sendmail.open("GET", "./php/aqsens_order.php?orderInfo="+JSON.stringify(orderInfo),true);
    sendmail.send();
    // 4. This will be called after the response is received
    sendmail.onload = function() {
      if (sendmail.status !== 200) { // analyze HTTP status of the response
        alert(`Error ${sendmail.status}: ${sendmail.statusText}`); // e.g. 404: Not Found
      } else { // show the result
            if (wb.lng === 'BG')
                 alertMes='Поръчката е изпратена!';
            else
                alertMes='Order was sent!';
            alert(alertMes);
      }
    };
    sendmail.onerror = function() {
        if (wb.lng === 'BG')
            alertMes='Възникна грешка, опитайте отново!';
        else
            alertMes='Error ocur, please try again later!';
        alert(alertMes);
    };
}

export var newaqsens_data ={
    id:'',
    mail:'',
    pass:'12345678',
    name:'',
    family:'',
    lat:'',
    lng:'',
    c_id:''
};
var newAQmarker_icon=L.icon({
    iconUrl: aqsens.bPurple,
    shadowUrl: '/res/ballon_shadow.svg',
    iconSize:     [48, 48], // size of the icon
    shadowSize:   [48, 48], // size of the shadow
    iconAnchor:   [24,48], // point of the icon which will correspond to marker's location
    shadowAnchor: [20,24],  // the same for the shadow
    popupAnchor:  [0,-48] // point from which the popup should open relative to the iconAnchor
    });
var newAQmarker;

function setAQsens(){
    newaqsens_data.id=document.getElementById("aqnum").value;
    newaqsens_data.id=newaqsens_data.id.toUpperCase();
    newaqsens_data.mail=document.getElementById("aqmail").value;
    newaqsens_data.name=document.getElementById("aqname").value;
    newaqsens_data.family=document.getElementById("aqfamily").value;
    getCID(newaqsens_data.mail);
    closeMenu();
    newAQmarker = new L.marker(
             wb.map.getCenter(),
            {icon:newAQmarker_icon,draggable:true,autoPan:true, opacity:1, id:newaqsens_data.id});
    aqsens.AQmarkers.invoke('setOpacity',0.5);
    var newpopup;
     if (wb.lng === 'BG')
            newpopup = "<button class='confirmB' id='puthere'>Постави тук!</button>";
        else
            newpopup = "<button class='confirmB' id='puthere'>Place here!</button>";
    newAQmarker.bindPopup(newpopup).addTo(wb.map);
    
    if (wb.lng === 'BG')
            alertMes='Завлачете балона до чочното местоположението на вашият AQsens и потвърдете с настикането му';
        else
            alertMes='Drag the balloon to the exact loation of your AQsens and confirm with presing it.';
    alert(alertMes);
}

export function writeNewAqsens(){
    newAQmarker.dragging.disable();
    newAQmarker.options.autoPan=false;
    newAQmarker.options.dragging=false;
    newAQmarker.setOpacity(1);
    newAQmarker.closePopup();
    newAQmarker.unbindPopup();
    newaqsens_data.lat = newAQmarker.getLatLng().lat;
    newaqsens_data.lng = newAQmarker.getLatLng().lng;
    newAQmarker.bindTooltip("Storing AQsens...").openTooltip();
    var ask; 
    var retdata;
  
    ask = new XMLHttpRequest();
    ask.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
      retdata =this.responseText;

      console.log(retdata);
      if (retdata==='New record created successfully'){
        newAQmarker.closeTooltip();
        newAQmarker.unbindTooltip();
        aqsens.AQmarkers.addLayer(newAQmarker);
        newAQmarker=null;
        aqsens.AQmarkers.invoke('setOpacity',1);
        aqsens.updateAQmarkers();
      }
      }
  };
  ask.open("GET", "./php/add_aqsens.php?AQjson="+JSON.stringify(newaqsens_data),true);
  ask.send();

}