/******************************************************************************/
/* Copyright © 2022 bTEND Ltd. All right reserved.                            */
/******************************************************************************/
/******************************************************************************/ 
/*    Author     : Dimitar Krastev at d.krastev@btend.bg                      */
/******************************************************************************/
import * as wbCtrl from './wb_controls.js';
import * as aqsens from './aqsens.js';
import * as wbchart from './aqcharts.js'
import * as wbmenu from './wb_menu.js'


var indexStr ='Air quality, AQI, PM10, PM2.5, SPL, ФПЧ, качество на въздуха';
export var locEU = [47.739,17.358],locHome;
export var zoomLoc,zoomEU;
export var lng ='BG';


if(navigator.language === 'bg')
    lng = 'BG';
else
    lng = 'EN';

export function setLng(set_lng){
    lng=set_lng;
}

if (L.Browser.mobile) {
    zoomLoc = 11;
    zoomEU = 4;
    }else{
    zoomLoc = 12;
    zoomEU = 5;
    }   
/******************************************************************************/
/* Placing map                                                                */
/******************************************************************************/
var baselayer = L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
            attribution: '<a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap</a> | <a href="https://www.btend.bg" target="_blank">© bTEND Ltd</a> | <a>'+indexStr+'</a>'
    });
    
export var map = L.map('map',{
        fadeAnimation: true,
        zoomControl: true,
        minZoom: 3,
        maxBounds: [[84.01602, 160.48828],[-78.7335, -139.04297]],
        zoom: zoomEU,
        center: locEU,
        layers: baselayer
        });
/******************************************************************************/
/* Placing AQsens                                                             */
/******************************************************************************/
aqsens.buildAQmarkers();
setInterval(aqsens.updateAQmarkers,60000);


/******************************************************************************/
/* add maps controls                                                           */
/******************************************************************************/
wbCtrl.placeFullscreen('topright');
map.zoomControl.setPosition('topright');
wbCtrl.placeLocButton('topright');
wbCtrl.placeSensButton('topright');
wbCtrl.placeMenuButton('topleft');
wbCtrl.placeShopButton('topleft');

/******************************************************************************/
/* map handlers                                                               */
/******************************************************************************/
map.on('click',function(){
    wbchart.closeChart(); 
    wbmenu.closeMenu();
});
map.once('layeradd',function(){
    map.locate({setView: true, maxZoom: zoomLoc, timeout:60000});
    document.querySelectorAll(".leaflet-top, .leaflet-right")
                    .forEach((elem) => elem.classList.add("shake"));
});

map.once('locationerror', function(){
    locHome=locEU;
    zoomLoc=zoomEU;
});
map.once('locationfound', function(){
    if(aqsens.checkMarkerView()){
        locHome = map.getCenter();
    }else{
        locHome = locEU; 
        zoomLoc = zoomEU;
        map.setView(locHome,zoomLoc);
    }
});
map.on('popupopen', function(){
    wbchart.closeChart(); 
    wbmenu.closeMenu();
    if (document.getElementsByClassName('popB').length !== 0){
        //history button
        document.getElementsByClassName('popB')[document.getElementsByClassName('popB').length-2]
                 .addEventListener("click", function(e){
                    wbchart.buildChart(this.id,this.parentNode.firstChild.id);
        });
        //analyze button
        document.getElementsByClassName('popB')[document.getElementsByClassName('popB').length-1]
                 .addEventListener("click", function(e){
                    wbchart.buildChart(this.id,this.parentNode.firstChild.id);
        });
    }
    else if(document.getElementById('puthere')){
        document.getElementById('puthere').addEventListener('click',wbmenu.writeNewAqsens);
    }
});
map.on('dialog:opened', function(){
    wbchart.closeChart();
});
map.on('dialog:closed', function(){
    wbmenu.closeMenu();
    document.getElementById('menu_bt').style.display = "block";
    document.getElementById('shop_bt').style.display = "block";
});
/******************************************************************************/
/* window event handlers                                                      */
/******************************************************************************/
window.addEventListener('resize', function(){
    wbchart.closeChart(); 
    wbmenu.closeMenu(); 
    setTimeout(wbCtrl.adjustLegend, 100 );
});
