/******************************************************************************/
/* Copyright © 2022 bTEND Ltd. All right reserved.                            */
/******************************************************************************/
/******************************************************************************/ 
/*    Author     : Dimitar Krastev at d.krastev@btend.bg                      */
/******************************************************************************/
import * as wb from './index.js';
import * as aqsens from './aqsens.js';
import * as wbchart from './aqcharts.js'
import * as wbmenu from './wb_menu.js'

export var locB;
export var sensB;
export var menuB;
export var shopB;

export function placeFullscreen(position){
    if ((/iPad|iPhone|iPod/.test(navigator.userAgent))||(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)){
    document.documentElement.style.setProperty('height', `100vh`, 'important');
   }else{
       wb.map.addControl(new L.Control.Fullscreen({
       title: {'false': 'View Fullscreen','true': 'Exit Fullscreen'},
       position: position
       }));
   }
}
export function placeMenuButton(position){
    menuB = L.easyButton({
            position:  position,
            id:        'menu_bt',      
            type:      'replace',  
            leafletClasses:   false,
            states:[
                {
                    stateName: 'menuO',
                    icon: '<img src="/res/OpenBook.svg" alt="open">',
                    title: 'Open menu',
                    onClick: function(btn){
                        btn.state('menuC');
                        wbmenu.openMenu();
                        }
                },{
                    stateName: 'menuC',
                    icon: '<img src="/res/OpenBook.svg" alt="close">',
                    title: 'Close menu',
                    onClick: function(btn){
                        btn.state('menuO');
                        if(wbmenu.menuWin)
                            wbmenu.closeMenu();
                        }
                }
              ]
            }).addTo(wb.map);
L.DomUtil.addClass(document.getElementById('menu_bt'),'menuB');
}
export function placeShopButton(position){
    shopB = L.easyButton({
            position:  position,
            id:        'shop_bt',      
            type:      'replace',  
            leafletClasses:   false,
            states:[
                {
                    stateName: 'shopO',
                    icon: '<img src="/res/Shop2.svg" alt="open">',
                    title: 'Buy AQsens',
                    onClick: function(btn){
                        btn.state('shopC');
                        wbmenu.openShopMenu();
                        }
                },{
                    stateName: 'shopC',
                    icon: '<img src="/res/Shop2.svg" alt="close">',
                    title: 'Close shop',
                    onClick: function(btn){
                        btn.state('shopO');
                        wbmenu.closeMenu();
                        }
                }
              ]
            }).addTo(wb.map);
L.DomUtil.addClass(document.getElementById('shop_bt'),'shopB');
}
export function placeLocButton(position){
    locB = L.easyButton({
            position:  position,
            id:        'loc_bt',       
            type:      'replace',
            leafletClasses:   false,
            states:[
                {
                    stateName: 'global',
                    icon: '<img src="/res/earth.svg" alt="earth">',
                    title: 'All AQsens',
                    onClick: function(btn){
                        wb.map.setView(wb.locEU,wb.zoomEU);
                        btn.state('home');
                        wb.map.closePopup();
                        wbchart.closeChart();
                        wbmenu.closeMenu();
                        }
                },{
                    stateName: 'home',
                    icon: '<img src="/res/home.svg" alt="home">',
                    title: 'Home',
                    onClick: function(btn){
                        wb.map.setView(wb.locHome,wb.zoomLoc);
                        btn.state('global');
                        wb.map.closePopup();
                        wbchart.closeChart();
                        wbmenu.closeMenu();
                        }
                }
              ]
            }).addTo(wb.map);
L.DomUtil.addClass(document.getElementById('loc_bt'),'locB');
}
export function placeSensButton(position){
    sensB = L.easyButton({
            position:  position,       
            id:        'sens_bt',       
            type:      'replace',    
            leafletClasses:   false,
            states: [
                {
                    stateName: 'pm2',
                    icon:      '<img src="/res/pm25.svg" id="lPic2" alt="PM2,5">',
                    title:     'Change View',
                    onClick: function(btn) {
                        btn.state('spl');
                        aqsens.updateAQmarkersView();
                        adjustLegend();
                        wb.map.closePopup();
                        wbchart.closeChart();
                        wbmenu.closeMenu();
                    }
                },{
                    stateName: 'spl',    
                    icon:'<img src="/res/spl.svg" id="lPicS" alt="SPL">',   
                    title: 'Change View',    
                    onClick: function(btn) {
                        btn.state('pm10');
                        aqsens.updateAQmarkersView();
                        adjustLegend();
                        wb.map.closePopup();
                        wbchart.closeChart();
                        wbmenu.closeMenu();
                    }    
                },{
                    stateName: 'pm10',
                    icon:      '<img src="/res/pm10.svg" id="lPic10" alt="PM10">',
                    title:     'Change View',
                    onClick: function(btn) {
                        btn.state('pm2');
                        aqsens.updateAQmarkersView();
                        adjustLegend();
                        wb.map.closePopup();
                        wbchart.closeChart();
                        wbmenu.closeMenu();
                    }
                }
            ]
            }).addTo(wb.map);
L.DomUtil.addClass(document.getElementById('sens_bt'),'legend');
adjustLegend();
}
 export function adjustLegend(){
    var vportH = window.innerHeight;
    var vportW = window.innerWidth;
    if((vportH>=350)&&(vportH<=560)){
        document.getElementById("sens_bt").style.width='50px';
        document.getElementById("sens_bt").style.height='230px';
        if(document.getElementById("lPic2")){
            document.getElementById("lPic2").style.width='46px';
            document.getElementById("lPic2").style.height='226px';
        }
        if(document.getElementById("lPic10")){
            document.getElementById("lPic10").style.width='46px';
            document.getElementById("lPic10").style.height='226px';
        }
        if(document.getElementById("lPicS")){
            document.getElementById("lPicS").style.width='46px';
            document.getElementById("lPicS").style.height='226px';
        }
    }
    if((vportH>=200)&&(vportH<=349)){
        document.getElementById("sens_bt").style.width='40px';
        document.getElementById("sens_bt").style.height='170px';
        if(document.getElementById("lPic2")){
            document.getElementById("lPic2").style.width='36px';
            document.getElementById("lPic2").style.height='166px';
        }
        if(document.getElementById("lPic10")){
            document.getElementById("lPic10").style.width='36px';
            document.getElementById("lPic10").style.height='166px';
        }
        if(document.getElementById("lPicS")){
            document.getElementById("lPicS").style.width='36px';
            document.getElementById("lPicS").style.height='166px';
        }
    }  
    if(vportH>=560){ 
        document.getElementById("sens_bt").style.width='50px';
        document.getElementById("sens_bt").style.height='350px';
        if(document.getElementById("lPic2")){
            document.getElementById("lPic2").style.width='46px';
            document.getElementById("lPic2").style.height='346px';
        }
        if(document.getElementById("lPic10")){
            document.getElementById("lPic10").style.width='46px';
            document.getElementById("lPic10").style.height='346px';
        }
        if(document.getElementById("lPicS")){
            document.getElementById("lPicS").style.width='46px';
            document.getElementById("lPicS").style.height='346px';
        }
    }
 }
/*****************************end of wb_controls*******************************/

