/******************************************************************************/
/* Copyright © 2022 bTEND Ltd. All right reserved.                            */
/******************************************************************************/
/******************************************************************************/ 
/*    Author     : Dimitar Krastev at d.krastev@btend.bg                      */
/******************************************************************************/

export var info = {
    bg: "<article id='inf'>"+
          "<h2>WhatBreathe</h2>"+
          "<h2><a href='https://www.letaballoonout.whatbreathe.com' target='_blank'><span style='float: left;'>#letaballoonout</span><span style='float: right;'>#пуснибалон</span></a></h2>"+
          "<br><br>"+
          "<p style= 'font-weight: bold;'>"+
          "&nbsp;&nbsp;&nbsp;&nbsp;WhatBreathe е услуга, която събира данни от AQsens&#x2122;"+
          " устройства за измерване параметрите на въздуха и шумовото замърсяване"+
          " и ги визуализира според географското положение на сензорите"+
          "<img src='./res/ballon_green.svg' alt='balloon' width='22' height='22'>"+
          "Нашите балони са умни, променят настроението си (цвета си) според моментното измерване на качеството на заобикалящия ги въздух"+
          "<img src='./res/ballon_green.svg' alt='balloon' width='22' height='22'>"+
          "Склонни са, ако ги натиснете, да дават информация без да се пукат"+
          "<img src='./res/ballon_green.svg' alt='balloon' width='22' height='22'>"+
          "Пазят историята на всички измервания за 7 дни назад"+
          "<img src='./res/ballon_green.svg' alt='balloon' width='22' height='22'>"+
          "Могат да анализират събраните данни, оценявайки ги според показателите WBI и SLI"+
          "<img src='./res/ballon_green.svg' alt='balloon' width='22' height='22'>"+
          "WhatBreathe разработи специално показетелите WBI и SLI за по-ясна и точна оценка на качеството на въздуха"+
          "<img src='./res/ballon_green.svg' alt='balloon' width='22' height='22'><br>"+
          "<span style='font-size:120%;'>WBI</span> - индекс какво дишаме"+
          ", който показва процентното отношение на качеството на въздуха в зададен времеви период"+
          "<img src='./res/ballon_green.svg' alt='balloon' width='22' height='22'><br>"+
          "<span style='font-size:120%;'>SLI</span> - индекс на нивото на шумовото замърсяване"+
          ", който показва процентното отношение на нивото на шума в зададен времеви период"+
          "<img src='./res/ballon_green.svg' alt='balloon' width='22' height='22'><br><br>"+
          "Колкото повече балони, толкова повече балони"+
          "<img src='./res/ballon_green.svg' alt='balloon' width='22' height='22'><br><br>"+
          "</p>"+
          "<p style= 'font-weight: bold; text-align: right;'>"+
          "Екипа на bTEND Ltd </p>"+
        "</article>",
    en: "<article id='inf'>"+
          "<h2>WhatBreathe</h2>"+
          "<h2><a href='https://www.letaballoonout.whatbreathe.com' target='_blank'><span style='float: left;'>#letaballoonout</span><span style='float: right;'>#пуснибалон</span></a></h2>"+
          "<br><br>"+
          "<p style= 'font-weight: bold;'>"+
          "&nbsp;&nbsp;&nbsp;&nbsp;WhatBreathe is a service, which collects data from AQsens&#x2122;"+
          " for measuring air quality parameters and sound pressure level"+
          " and visualizes them according to the geographic location of the sensors"+
          "<img src='./res/ballon_green.svg' alt='balloon' width='22' height='22'>"+
          "Our balloons are smart, they can change their mood (color) according to the quality of the measured air parameters"+
          "<img src='./res/ballon_green.svg' alt='balloon' width='22' height='22'>"+
          "They tend to give you information without popping, if you press them"+
          "<img src='./res/ballon_green.svg' alt='balloon' width='22' height='22'>"+
          "As well as keep history of all measurments for the past 7 days"+
          "<img src='./res/ballon_green.svg' alt='balloon' width='22' height='22'>"+
          "They can analyze and estimate collected data according to WBI and SLI"+
          "<img src='./res/ballon_green.svg' alt='balloon' width='22' height='22'>"+
          "WhatBreathe especially created indexes WBI and SLI, for clear and better evaluation of air quality"+
          "<img src='./res/ballon_green.svg' alt='balloon' width='22' height='22'><br>"+
          "<span style='font-size:120%;'>WBI</span> - What we breathe index"+
          ", shows the percentage of air quality in the given period"+
          "<img src='./res/ballon_green.svg' alt='balloon' width='22' height='22'><br>"+
          "<span style='font-size:120%;'>SLI</span> - Sound level index"+
          ", shows the percentage of noise level in the given period"+
          "<img src='./res/ballon_green.svg' alt='balloon' width='22' height='22'><br><br>"+
          "The more balloons the better balloons"+
          "<img src='./res/ballon_green.svg' alt='balloon' width='22' height='22'><br><br>"+
          "</p>"+
          "<p style= 'font-weight: bold; text-align: right;'>"+
          "bTEND Ltd team </p>"+
        "</article>"
};

export var description = {
    bg:  '<article id="desc">'+
            "<h2>AQsens&#x2122</h2>"+
              '<p style= "font-weight: bold;">'+
              '<img src="res/aqsensBuy.jpg" alt="aqsens" style="float:left;  margin-right:20px;">'+
              '&nbsp;&nbsp;&nbsp;&nbsp;AQsens&#x2122; е създаден да измерва: температура на въздуха, относителна влажност, атмосферно налягане, ниво на шума (SPL) и'+ 
              ' концентрация на фини прахови частици (ФПЧ) с размери 1, 2.5 и 10 микрометра [µm]'+
              '<img src="res/ballon_green.svg" alt="ballon" width="22" height="22">'+
              'AQsens&#x2122; предава измерените стойности на всеки час'+
              '<img src="res/ballon_green.svg" alt="ballon" width="22" height="22">'+
              'Работи с батерия, която гарантира автономната му работа за поне три седмици'+
              '<img src="res/ballon_green.svg" alt="ballon" width="22" height="22">'+
              'При изтощаване на батерията услугата WhatBreathe '+ 
              'ще ви напомни да заредите Вашия AQsens&#x2122;, като ви изпрати съобщение на имейла от регистрационната форма'+
              '<img src="res/ballon_green.svg" alt="ballon" width="22" height="22">'+
              'AQsens&#x2122; може да бъде зареден със зарядното за Вашия телефон или чрез USB порт на компютър'+
              '<img src="res/ballon_green.svg" alt="ballon" width="22" height="22">'+
              'Оборудван е със стандартен micrо USB порт, както Вашия телефон'+
              '<img src="res/ballon_green.svg" alt="ballon" width="22" height="22">'+
               'Времето за зареждане е около 10 часа'+
              '<img src="res/ballon_green.svg" alt="ballon" width="22" height="22">'+
              'По време на зареждане AQsens&#x2122; не прави измервания'+
              '<img src="res/ballon_green.svg" alt="ballon" width="22" height="22">'+
              'За правилни измервания трябва да поставите Вашия AQsens&#x2122; навън, така че да не бъде изложен на пряка слънчева светлина и да бъде защитен от дъжд'+
              '<img src="res/ballon_green.svg" alt="ballon" width="22" height="22">'+
        '</article>',
    en: '<article id="desc">'+
            "<h2>AQsens&#x2122</h2>"+
                '<p style= "font-weight: bold;">'+
              '<img src="res/aqsensBuy.jpg" alt="aqsens" style="float:left;  margin-right:20px;">'+
              '&nbsp;&nbsp;&nbsp;&nbsp;AQsens&#x2122; is design to measure - air temeperature, relative humidity, air pressure, sound pressure level (SPL) and'+ 
              ' partical matter concentration (PM) with dimension 1, 2.5 and 10 micrometer [µm]'+
              '<img src="res/ballon_green.svg" alt="ballon" width="22" height="22">'+
              'AQsens&#x2122; transmits measured data every hour'+
              '<img src="res/ballon_green.svg" alt="ballon" width="22" height="22">'+
              'It works on battery that garanties at least two weeks of work'+
              '<img src="res/ballon_green.svg" alt="ballon" width="22" height="22">'+
              'When the battery dies, service WhatBreathe'+ 
              ' will remind you to charge your AQsens&#x2122;, by sending you an email'+
              '<img src="res/ballon_green.svg" alt="ballon" width="22" height="22">'+
              'AQsens&#x2122; can be charged by your phones\' charger or your computers\' USB, it is equipped '+
              ' with a standard micrо USB port, just like your phone'+
              '<img src="res/ballon_green.svg" alt="ballon" width="22" height="22">'+
              'Charging time is 10 hours'+
              '<img src="res/ballon_green.svg" alt="ballon" width="22" height="22">'+
              'While charging AQsens&#x2122; dosn\'t measure'+
              '<img src="res/ballon_green.svg" alt="ballon" width="22" height="22">'+
              'For correct measurments your AQsens&#x2122; should be outside, placed so as to avoid direct sunlight and protected from rain'+
              '<img src="res/ballon_green.svg" alt="ballon" width="22" height="22">'+
        '</article>'
};

export var setupMobile = {
    bg: "<article id='stp_m'>"+
          "<h2>Настройка на AQsens&#x2122</h2>"+
          "<p style= 'font-weight: bold;'>"+
          "&nbsp;&nbsp;За да измерва коректно параметрите на въздуха, AQsens\u2122 трябва да бъде поставен навън без да е на пряка слънчева светлина, и без да бъде мокрен от дъжд"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "При нормална работа няма светлинна индикация, светодиода е изгаснал, мига само когато AQsens\u2122 е включен в зарядно устроийство"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "<br>&nbsp&nbsp&nbsp&nbspВие ще получите вашия AQsens\u2122 в не активно състояние"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "За да активирате AQsens\u2122 е необходимо да го включите към зарядно устройство или към USB порт на компютър"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "AQsens\u2122 ще се събуди и зеления светодиод ще светне постоянно за около 3 минути"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "Сега изключете AQsens\u2122 от зарядното"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "Когато зеления светодиод мига по този начин <i class='fa fa-circle fa-2x' id='stpL' aria-hidden='true'></i>"+
          " AQsens\u2122 е в режим на конфигурация и очаква да бъде настроен"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "Ако не успеете да настроите AQsens\u2122 до 15 минути, той ще изпадне в не активно състояние и трябва да започнете процедурата от начало"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "След като AQsens\u2122 е настроен, моля изчакайте 5 минути преди да регистрирате AQsens\u2122"+
          " в услугата WhatBreathe"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "<br>&nbsp&nbsp&nbsp&nbspКогато AQsens\u2122 е в режим на настройка, влезте в настроийките за WiFi на вашия телефон, в списъка с налични WiFi мрежи ще видите AQsens-xxxx"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "Където хххх - са последните четири цифри от серийния номер на вашия AQsens\u2122, който може да видите на стикер на дъното му"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "След като сте се свързали към WiFi на вашия AQsens\u2122, телефона ви може да ви информира, че няма интернет, "+
          "изберете да останете в неговата мрежа, иначе няма да може да го настроите"+
           "<img src='./res/ballon_green.svg' width='22' height='22'>"+
           "Когато сте в страницата за настройка на вашия AQsens\u2122, е възможно да получите съобщение, че страницата неможе да се зареди, "+
          "не я опреснявайте! Изчакайте, страницата ще се зареди"+ 
           "<img src='./res/ballon_green.svg' width='22' height='22'>"+
           "Не позволявайте дисплея на телефона да изгасне"+
           "<img src='./res/ballon_green.svg' width='22' height='22'>"+
           "Убедете се, че сте във WiFi мрежата на вашия AQsens\u2122"+
           "<img src='./res/ballon_green.svg' width='22' height='22'>"+
           "Сега може да продължите към страницата за настроийка на AQsens\u2122 от бутона по долу"+
           "<img src='./res/ballon_green.svg' width='22' height='22'><br><br><br>"+
           "<button class='buttonW' id='aqpage'><img src='res/WiFI.svg' width=32px height=32px>AQsens\u2122</button>"+
           "<br><br>"+
        "</article>",
    en: "<article id='stp_m'>"+
          "<h2>AQsens&#x2122 setup</h2>"+
          "<p style= 'font-weight: bold;'>"+
          "&nbsp;&nbsp;To measure correctly air parameters, your AQsens\u2122 should be placed outside, without direct sun light and protected from rain"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "During normal work LED will not be lit, LED will blink only when AQsens\u2122 is charging"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "<br>&nbsp&nbsp&nbsp&nbspYou will receive AQsens\u2122 in hibernation mode"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "To wake up AQsens\u2122 you need to connect it to a charger or PC USB port"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "AQsens\u2122 will wake up and the green LED will light for about 3 minutes"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "Now disconnect AQsens\u2122 from charger"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "When the green LED starts blinking like this <i class='fa fa-circle fa-2x' id='stpL' aria-hidden='true'></i>"+
          " AQsens\u2122 is in setup mode and expects to be configured"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "If calibration of AQsens\u2122 is not done in 15 minutes, the device will go in hibernation mode and you will need to start the procedure from the begining"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "After AQsens\u2122 is setup, please wait at least 5 minutes before registering your AQsens\u2122"+
          " to the service WhatBreathe"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "<br>&nbsp&nbsp&nbsp&nbspWhen AQsens\u2122 is in setup mode, please enter in your WiFi setings on your phone, you will see AQsens-xxxx in the list of available WiFi networks"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "Where хххх - are the last four digit of your AQsens\u2122 serial number, you can see it on the bottom of the device"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "After connecting to AQsens\u2122 WiFi network, it is posible for your phone to alarm you that this network has not internet connection, "+
          "please choose to stay in it, otherwise you can't setup your AQsens\u2122"+
           "<img src='./res/ballon_green.svg' width='22' height='22'>"+
           "When you are in the setup page of your AQsens\u2122,it's possible to recive a message that the page can't load, "+
          "don't refresh it! Please wait for the page to load"+ 
           "<img src='./res/ballon_green.svg' width='22' height='22'>"+
           "Don't turn off your phone"+
           "<img src='./res/ballon_green.svg' width='22' height='22'>"+
           "Be sure, that you are loged in your AQsens\u2122 WiFi network"+
           "<img src='./res/ballon_green.svg' width='22' height='22'>"+
           "Now you can continue to AQsens\u2122 setup page from the button bellow"+
           "<img src='./res/ballon_green.svg' width='22' height='22'><br><br><br>"+
           "<button class='buttonW' id='aqpage'><img src='res/WiFI.svg' width=32px height=32px>AQsens\u2122</button>"+
           "<br><br>"+
        "</article>"
};

export var setupPC = {
    bg: "<article id='stp'>"+
          "<h2>Настройка на AQsens&#x2122</h2>"+
          "<h1 style= 'font-weight: bold; '>"+
          "Вашия AQsens\u2122 може да бъде настроен само през телефона Ви "+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "<br>Моля посетете WhatBreathe от Вашия телефон"+
          "<img src='./res/ballon_green.svg' width='22' height='22'></h1>"+
          "<p style= 'font-weight: bold;'>"+
          "&nbsp;&nbsp;За да измерва коректно параметрите на въздуха, AQsens\u2122 трябва да бъде поставен навън без да е на пряка слънчева светлина, и без да бъде мокрен от дъжд"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "При нормална работа няма светлинна индикация, светодиода е изгаснал, мига само когато AQsens\u2122 е включен в зарядно устроийство"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "<br>&nbsp&nbsp&nbsp&nbspВие ще получите вашия AQsens\u2122 в не активно състояние"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "За да активирате AQsens\u2122 е необходимо да го включите към зарядно устройство или към USB порт на компютър"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "AQsens\u2122 ще се събуди и зеления светодиод ще светне постоянно за около 3 минути"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "Сега изключете AQsens\u2122 от зарядното"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "Когато зеления светодиод мига по този начин <i class='fa fa-circle fa-2x' id='stpL' aria-hidden='true'></i>"+
          " AQsens\u2122 е в режим на конфигурация и очаква да бъде настроен"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "Ако не успеете да настроите AQsens\u2122 до 15 минути, той ще изпадне в не активно състояние и трябва да започнете процедурата от начало"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "След като AQsens\u2122 е настроен, моля изчакайте 5 минути преди да регистрирате AQsens\u2122"+
          " в услугата WhatBreathe"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "<br>&nbsp&nbsp&nbsp&nbspКогато AQsens\u2122 е в режим на настройка, влезте в настроийките за WiFi на вашия телефон, в списъка с налични WiFi мрежи ще видите AQsens-xxxx"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "Където хххх - са последните четири цифри от серийния номер на вашия AQsens\u2122, който може да видите на стикер на дъното му"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "След като сте се свързали към WiFi на вашия AQsens\u2122, телефона ви може да ви информира, че няма интернет, "+
          "изберете да останете в неговата мрежа, иначе няма да може да го настроите"+
           "<img src='./res/ballon_green.svg' width='22' height='22'>"+
           "Когато сте в страницата за настройка на вашия AQsens\u2122, е възможно да получите съобщение, че страницата неможе да се зареди, "+
          "не я опреснявайте! Изчакайте, страницата ще се зареди"+ 
           "<img src='./res/ballon_green.svg' width='22' height='22'>"+
           "Не позволявайте дисплея на телефона да изгасне"+
           "<img src='./res/ballon_green.svg' width='22' height='22'>"+
           "Убедете се, че сте във WiFi мрежата на вашия AQsens\u2122"+
           "<img src='./res/ballon_green.svg' width='22' height='22'>"+
        "</article>",
    en: "<article id='stp'>"+
          "<h2>AQsens&#x2122 setup</h2>"+
          "<h1 style= 'font-weight: bold;'>"+
          "The setup of your AQsens\u2122 can be done only from your phone"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "<br>Please visit WhatBreathe from yours"+
          "<img src='./res/ballon_green.svg' width='22' height='22'></h1>"+
          "<p style= 'font-weight: bold;'>"+
          "&nbsp;&nbsp;To measure correctly air parameters, your AQsens\u2122 should be placed outside, without direct sun light and protected from rain"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "During normal work LED will not be lit, LED will blink only when AQsens\u2122 is charging"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "<br>&nbsp&nbsp&nbsp&nbspYou will receive AQsens\u2122 in hibernation mode"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "To wake up AQsens\u2122 you need to connect it to a charger or PC USB port"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "AQsens\u2122 will wake up and the green LED will light for about 3 minutes"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "Now disconnect AQsens\u2122 from charger"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "When the green LED starts blinking like this <i class='fa fa-circle fa-2x' id='stpL' aria-hidden='true'></i>"+
          " AQsens\u2122 is in setup mode and expects to be configured"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "If calibration of AQsens\u2122 is not done in 15 minutes, the device will go in hibernation mode and you will need to start the procedure from the begining"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "After AQsens\u2122 is setup, please wait at least 5 minutes before registering your AQsens\u2122"+
          " to the service WhatBreathe"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "<br>&nbsp&nbsp&nbsp&nbspWhen AQsens\u2122 is in setup mode, please enter in your WiFi setings on your phone, you will see AQsens-xxxx in the list of available WiFi networks"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "Where хххх - are the last four digit of your AQsens\u2122 serial number, you can see it on the bottom of the device"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "After connecting to AQsens\u2122 WiFi network, it is posible for your phone to alarm you that this network has not internet connection, "+
          "please choose to stay in it, otherwise you can't setup your AQsens\u2122"+
           "<img src='./res/ballon_green.svg' width='22' height='22'>"+
           "When you are in the setup page of your AQsens\u2122,it's possible to recive a message that the page can't load, "+
          "don't refresh it! Please wait for the page to load"+ 
           "<img src='./res/ballon_green.svg' width='22' height='22'>"+
           "Don't turn off your phone"+
           "<img src='./res/ballon_green.svg' width='22' height='22'>"+
           "Be sure, that you are loged in your AQsens\u2122 WiFi network"+
           "<img src='./res/ballon_green.svg' width='22' height='22'>"+
        "</article>"
};

export var register = {
    bg: "<div id='divreg'>"+
          "<h2>Регистрация на AQsens&#x2122</h2>"+
          "<br><form id='regform' style= 'font-weight: bold;'>"+
          "AQsens\u2122 сериен номер:"+
          "<input id='aqnum' type='text' name='aqid' style='float: left; width: 98%;'><br>"+
          "<br>Електронна поща:"+
          "<input id='aqmail' type='email' autocomplete='on' name='mail' style='float: left; width: 98%;'disabled><br>"+
//          "<br>Парола: мин. 8 символа"+
//          "<input id='aqpass' type='password' autocomplete='on' name='pass' style='float: left; width: 97%;'  disabled><br>"+
//          "<br>Отново парола:"+
//          "<input id='aqpass1' type='password' autocomplete='on' name='pass1' style='float: left; width: 97%;' disabled><br>"+
          "<br>Име:"+
          "<input id='aqname' type='text' autocomplete='on' name='first_name' style='float: left; width: 98%;' disabled><br>"+
          "<br>Фамилия:"+
          "<input id='aqfamily' type='text' autocomplete='on' name='family_name' style='float: left; width: 98%;' disabled><br>"+
          "</form>"+
          "<p><br> Всички полета са задължителни!</p>"+
          "<p style='font-style: italic;color: blue;font-weight: normal;'>"+
          "Ние ценим Вашата поверителност!"+
          "<br>WhatBreathe не използва бисквитки и не събира информация за Вас, докато използвате услугата "+
          "<img src='res/ballon_green.svg' alt='ballon' width='22' height='22'> "+
          "Данните от регистрационната форма - имената Ви, мейл адресът и географското положение на "+
          "Вашия AQsens\u2122 - ще се използват само в услугата WhatBreathe "+
          "<img src='res/ballon_green.svg' alt='ballon' width='22' height='22'> "+
          "За да гарантираме функционалността му, ще получавате мейли единствено от "+
          "support@whatbreathe.com , когато Вашият AQsens\u2122 трябва да се зареди (на всеки три седмици) или, "+
          "ако не е предавал данни повече от 3 часа, но повторното напомняне ще е след 72 часа "+
          "<img src='res/ballon_green.svg' alt='ballon' width='22' height='22'>"+
          "<form id='gdprR' style= 'color: blue;'>"+
          "  <input type='checkbox' id='regChk' name='regAgreed' value='no'>"+
          "  <label for='regAgreed'> Съгласен съм с условията!</label>"+
          "</form>"+
         "<br>"+
         "<button type='button' class='confirmB' id='regok' disabled>Регистрирай</button>"+
         "<br><br><br></div>",
    en: "<div id='divreg'>"+
          "<h2>Register AQsens&#x2122</h2>"+
          "<br><form id='regform' style= 'font-weight: bold;'>"+
          "AQsens\u2122 SN:"+
          "<input id='aqnum' type='text' name='aqid' style='float: left;width: 98%;'><br>"+
          "<br>Email:"+
          "<input id='aqmail' type='email' autocomplete='on' name='mail' style='float: left;width: 98%;' disabled><br>"+
//          "<br>Password: min. 8 symbols"+
//          "<input id='aqpass' type='password' autocomplete='on' name='pass' style='float: left;width: 97%;' disabled><br>"+
//          "<br>Password again:"+
//          "<input id='aqpass1' type='password' autocomplete='on' name='pass1' style='float: left;width: 97%;' disabled><br>"+
          "<br>Name:"+
          "<input id='aqname' type='text' autocomplete='on' name='first_name' style='float: left;width: 98%;' disabled><br>"+
          "<br>Surname:"+
          "<input id='aqfamily' type='text' autocomplete='on' name='family_name' style='float: left;width: 98%;' disabled><br>"+
          "</form>"+
          "<p><br> All tabs are mandatory!</p>"+
          "<p style='font-style: italic;color: blue;font-weight: normal;'>"+
          "We value your privacy!"+
          "<br>WhatBreathe does not use cookies or collect your information while using the services "+
          "<img src='res/ballon_green.svg' alt='ballon' width='22' height='22'> "+
          "The data from the registration form, your names, email address, and geographic location of your "+
          "AQsens\u2122, will only be used in the service WhatBreathe "+
          "<img src='res/ballon_green.svg' alt='ballon' width='22' height='22'> "+
          "In order to guarantee the functionality of it, you will receive emails only from "+
          "support@whatbreathe.com , when your AQsens needs recharging (every two weeks) or if it hasn’t "+
          "transferred data in more then 3 hours, but the second reminder will be in 72 hours "+
          "<img src='res/ballon_green.svg' alt='ballon' width='22' height='22'>"+
          "<form id='gdprR' style= 'color: blue;'>"+
          "  <input type='checkbox' id='regChk' name='regAgreed' value='no'>"+
          "  <label for='regAgreed'> I agree to the terms!</label>"+
          "</form>"+
         "<br>"+
         "<button type='button' class='confirmB' id='regok' disabled>Registration</button>"+
         "<br><br><br></div>"                       
};

export var order = {
    bg: "<div id='divorder'><br>"+
        "<form  style= 'font-weight: bold; text-align: left;'>"+
        "<label for='aqpcs'>Поръчка на 1</label>"+
//        "<select name='aqpcs' id='aq_qu'>"+
//        "<option value='1'>1</option>"+
//        "<option value='2'>2</option>"+
//        "<option value='3'>3</option>"+
//        "</select>"+
        "<label> бр. AQsens&#x2122; на стойност</label><label id='sum'> - 150 лв.</label><br>"+
        "<p style='font-style: italic;color: blue;font-weight: normal;'>"+
        "Цената е крайна, с включена доставка до офис на ЕКОНТ за територията на България!</p>"+
        "</form>"+
        "<form id='orderform' style= 'font-weight: bold; text-align: left;'>"+
        "<label for='cname'>Име:</label><br>"+
        "<input id='cname' type='text' name='first_name' style='width: 98%;' ><br>"+
        "<label for='cfamily'>Фамилия:</label><br>"+
        "<input id='cfamily' type='text' name='family_name'  style='width: 98%;' ><br>"+
        "<label for='cmail'>Електронна поща:</label><br>"+
        "<input id='cmail' type='email' name='mail'  style='width: 98%;' ><br>"+
        "<label for='cphone'>Tелефон:</label><br>"+
        "<input id='cphone' type='tel' name='phone'  style='width: 98%;' ><br>"+
        "<label for='caddr'>Адрес за доставка:</label><br>"+
        "<input id='caddr' type='text' name='address' style='width: 98%;'>"+
        "</form>"+
        "<br><p style= 'font-weight: bold; text-align: left;'>Всички полета са задължителни!</p>"+
        "<p style= 'font-weight: bold; text-align: left;'>След като направите поръчката, нашият екип ще се свърже с Вас!</p>"+
        "<p style='font-style: italic;color: blue;font-weight: normal;'>"+
        "Ние ценим Вашата поверителност!"+
        "<br>WhatBreathe не използва бисквитки и не събира информация за Вас, докато използвате услугата"+
        "<img src='res/ballon_green.svg' alt='ballon' width='22' height='22'>"+
        "Данните от формата за поръчка - имената Ви, мейл адресът Ви, адресът за доставка "+
        "и телефонният Ви номер ще се използват само в услугата WhatBreathe"+
        "<img src='res/ballon_green.svg' alt='ballon' width='22' height='22'>"+
        "<form id='gdprO' style= 'color: blue;'>"+
        "<input type='checkbox' id='orderChk' name='regAgreed' value='no'>"+
        "<label for='orderAgreed'> Съгласен съм с условията!</label>"+
        "</form><br>"+
        "<button type='button' class='confirmB' id='orderOK' disabled>Поръчай</button>"+
        "<br><br><br>"+
        "</div>",
    en: "<div id='divorder'><br>"+
        "<form  style= 'font-weight: bold; text-align: left;'>"+
        "<label for='aqpcs'>Order 1</label>"+
//        "<select name='aqpcs' id='aq_qu'>"+
//        "<option value='1'>1</option>"+
//        "<option value='2'>2</option>"+
//        "<option value='3'>3</option>"+
//        "</select>"+
        "<label> pcs. AQsens&#x2122;  goes for</label><label id='sum'> - 150 лв.</label><br>"+
        "<p style='font-style: italic;color: blue;font-weight: normal;'>"+
        "The price is final including delivery to an ECONT office, in Bulgaria!</p>"+
        "</form>"+
        "<form id='orderform' style= 'font-weight: bold; text-align: left;'>"+
        "<label for='cname'>Name:</label><br>"+
        "<input id='cname' type='text' name='first_name' style='width: 98%;' ><br>"+
        "<label for='cfamily'>Surname:</label><br>"+
        "<input id='cfamily' type='text' name='family_name'  style='width: 98%;' ><br>"+
        "<label for='cmail'>Email:</label><br>"+
        "<input id='cmail' type='email' name='mail'  style='width: 98%;' ><br>"+
        "<label for='cphone'>Phone:</label><br>"+
        "<input id='cphone' type='tel' name='phone'  style='width: 98%;' ><br>"+
        "<label for='caddr'>Shipping address:</label><br>"+
        "<input id='caddr' type='text' name='address' style='width: 98%;'>"+
        "</form>"+
        "<br><p style= 'font-weight: bold; text-align: left;'> All tabs are mandatory!</p>"+
        "<p style= 'font-weight: bold; text-align: left;'>After you make the purchase, our team will contact you!</p>"+
        "<p style='font-style: italic;color: blue;font-weight: normal;'>"+
        "We value your privacy!"+
        "<br>WhatBreathe does not use cookies or collect your information while using the services "+
        "<img src='res/ballon_green.svg' alt='ballon' width='22' height='22'>"+
        "The data from the order form - your names, email address, delivery address "+
        "and your phone nummber will be used only in the service WhatBreathe"+
        "<img src='res/ballon_green.svg' alt='ballon' width='22' height='22'>"+
        "<form id='gdprO' style= 'color: blue;'>"+
        "<input type='checkbox' id='orderChk' name='regAgreed' value='no'>"+
        "<label for='orderAgreed'> I agree to the terms!</label>"+
        "</form><br>"+
        "<button type='button' class='confirmB' id='orderOK' disabled>Order</button>"+
        "<br><br><br>"+
        "</div>"
};

export var orderDone = {
    bg: "<article id='orderDone'>"+
          "<p style= 'font-weight: bold; text-align: center;'>"+
          "<br>Вашата поръчка е приета!<br>"+
          "До 24 часа нашия екип ще се свърже с вас за потвърждение!"+
         "</article>",
    en: "<article id='orderDone'>"+
          "<p style= 'font-weight: bold; text-align: center;'>"+
          "<br>Your order is placed!<br>"+
          "Within 24 hours, our team will contact you for confirmation!"+
         "</article>"
};

export var setupWIFI = {
    bg: "<article id='stp'>"+
          "<h2 id='stph1' style= 'text-align:center;'>Моля изчакайте...</h2><br>"+
          "<h2 id='stph2' style= 'text-align:center;'></h2><br><br><br>"+
          "<div id='stpl' class='loader'></div><br><br><br><br>"+
          "<button class='buttonW' id='aqwifi'><img src='res/32_wifi.svg'>AQsens\u2122</button>"+
        "</article>",
    en: "<article id='stp'>"+
          "<h2 id='stph1' style= 'text-align:center;'>Please wait...</h2><br>"+
          "<h2 id='stph2' style= 'text-align:center;'></h2><br><br><br>"+
          "<div id='stpl' class='loader'></div><br><br><br><br>"+
          "<button class='buttonW' id='aqwifi'><img src='res/32_wifi.svg'>AQsens\u2122</button>"+
        "</article>"                      
};

export var mailTo = {
    bg: "<div id='divmail'>"+
          "<h2>Обратна връзка</h2>"+
          "<form id='mailToform' style= 'font-weight: bold;'><br>"+
          "<label for='yname'>Име:</label>"+
          "<input id='yname' type='text' name='first_name' style='width: 98%;' enable><br><br>"+
          "<label for='yfamily'>Фамилия:</label>"+
          "<input id='yfamily' type='text' name='family_name' style='width: 98%;' enable><br><br>"+
          "<label for='ymail'>Електронна поща:</label>"+
          "<input id='ymail' type='email' name='mail' style='width: 98%;' enable><br><br>"+
          "<label for='omail'>Относно: </label>"+
          "<select id='omail'>&nbsp;&nbsp;"+
          "<option value='info@whatbreathe.com'>Информация</option>"+
          "<option value='support@whatbreathe.com'>Помощ</option>"+
          "</select><br><br>"+
          "<label for='ymes'>Вашето съобщение:</label><br>"+
          "<textarea id='ymes' rows='10' style='width: 98%;'></textarea>"+
          "</form>"+
          "<p style= 'font-weight: bold; text-align: left;'>Всички полета са задължителни!</p>"+
          "<p style='font-style: italic;color: blue;font-weight: normal;'>"+
          "Ние ценим Вашата поверителност!"+
          "<br>WhatBreathe не използва бисквитки и не събира информация за Вас, докато използвате услугата"+
          "<img src='/res/ballon_green.svg' alt='ballon' width='22' height='22'>"+
          "Данните от формата за обратна връзка - имената Ви и мейл адресът - ще се използват само в услугата WhatBreathe"+
          "<img src='/res/ballon_green.svg' alt='ballon' width='22' height='22'>"+
          "<form id='gdprMess' style= 'color: blue;'>"+
          "<input type='checkbox' id='messChk' name='messAgreed' value='no'>"+
          "<label for='messAgreed'> Съгласен съм с условията!</label>"+
          "</form>"+
          "<br>"+
          "<button type='button' class='confirmB' id='sendMess' disabled>Изпрати</button></div>"+
          "<br><br><br>",
    en: "<div id='divmail'>"+
          "<h2>Feedback</h2>"+
          "<form id='mailToform' style= 'font-weight: bold;'><br>"+
          "<label for='yname'>Name:</label>"+
          "<input id='yname' type='text' name='first_name' style='width: 98%;' enable><br><br>"+
          "<label for='yfamily'>Surname:</label>"+
          "<input id='yfamily' type='text' name='family_name' style='width: 98%;' enable><br><br>"+
          "<label for='ymail'>Email:</label>"+
          "<input id='ymail' type='email' name='mail' style='width: 98%;' enable><br><br>"+
          "<label for='omail'>About: </label>"+
          "<select id='omail'>&nbsp;&nbsp;"+
          "<option value='info@whatbreathe.com'>Information</option>"+
          "<option value='support@whatbreathe.com'>Help</option>"+
          "</select><br><br>"+
          "<label for='ymes'>Your message:</label><br>"+
          "<textarea id='ymes' rows='10' style='width: 98%;'></textarea>"+
          "</form>"+
          "<p style= 'font-weight: bold; text-align: left;'> All tabs are mandatory!</p>"+
          "<p style='font-style: italic;color: blue;font-weight: normal;'>"+
          "We value your privacy!"+
          "<br>WhatBreathe does not use cookies or collect your information while using the services "+
          "<img src='/res/ballon_green.svg' alt='ballon' width='22' height='22'>"+
          "The data from the feedback form, your names and email address, will only be used in the service WhatBreathe"+
          "<img src='/res/ballon_green.svg' alt='ballon' width='22' height='22'>"+
          "<form id='gdprMess' style= 'color: blue;'>"+
          "<input type='checkbox' id='messChk' name='messAgreed' value='no'>"+
          "<label for='messAgreed'> I agree to the terms!</label>"+
          "</form>"+
          "<br>"+
          "<button type='button' class='confirmB' id='sendMess' disabled>Send</button></div>"+
          "<br><br><br>"
};

export var sendDone = {
    bg: "<article id='messDone'>"+
          "<p style= 'font-weight: bold; text-align: center;'>"+
          "<br>Вашето съобщение е изпратено!<br>"+
          "До 24 часа нашия екип ще се свърже с вас!"+
         "</article>",
    en: "<article id='messDone'>"+
          "<p style= 'font-weight: bold; text-align: center;'>"+
          "<br>Your message is sent!<br>"+
          "Within 24 hours, our team will contact you!"+
         "</article>"
};

export var chartDescription = {
    bg: "<article id='chartD' >"+
          "<h1 style= 'text-align:center;'><img src='res/info.svg' width='32' height='32'>&nbsp;&nbsp;&nbsp;Легенда</h1>"+
          "<p style= 'font-weight: bold;'>"+
          "<span style='font-size:120%;'>WBI</span> - индекс какво дишаме"+
          "<img src='./res/ballon_green.svg' width='22' height='22'><bg>"+
          "Показва процентното отношение на качеството на въздуха в показания времеви период"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "<br><span style='font-size:120%;'>SLI</span> - индекс на нивото на шумовото замърсяване"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "Показва процентното отношение на нивото на шума в показания времеви период"+
          "<img src='./res/ballon_green.svg' width='22' height='22'><br></p>"+
//          "<table class='table' id='tbl1'>" +
//          "<tr><td style = 'font-weight: bold;'>WBI&nbsp&nbsp</td>"+
//          "<td style='background-color: #2eb72b;color: #445931; font-weight: bold;'>&nbsp0-25 µg/m<sup>3&nbsp</td>"+
//          "<td style='background-color: #e9e838;color: #445931; font-weight: bold;'>&nbsp20-30 µg/m<sup>3&nbsp</td>"+
//          "<td style='background-color: #e16527;color: #FFFFFF; font-weight: bold;'>&nbsp25-50 µg/m<sup>3&nbsp</td>"+
//          "<td style='background-color: #dd1f1d;color: #FFFFFF; font-weight: bold;'>&nbsp50-75 µg/m<sup>3&nbsp</td>"+
//          "<td style='background-color: #84223a;color: #FFFFFF; font-weight: bold;'>&nbsp75-800 µg/m<sup>3&nbsp</td>"+
//          "</tr>"+
//          "<tr><td style = 'font-weight: bold;'>SLI&nbsp&nbsp</td>"+
//          "<td style='background-color: #2eb72b;color: #445931;font-weight: bold;'>&nbsp&nbsp40-60 db&nbsp</td>"+
//          "<td style='background-color: #e9e838;color: #445931;font-weight: bold;'>&nbsp&nbsp60-80 db&nbsp</td>"+
//          "<td style='background-color: #e16527;color: #FFFFFF;font-weight: bold;'>&nbsp&nbsp80-90 db&nbsp</td>"+
//          "<td style='background-color: #dd1f1d;color: #FFFFFF;font-weight: bold;'>&nbsp&nbsp90-110 db&nbsp</td>"+
//          "<td style='background-color: #84223a;color: #FFFFFF;font-weight: bold;'>&nbsp&nbsp110-130 db&nbsp</td>"+
//          "</tr>"+
//          "</table>"+
        "</article>",
    en: "<article id='chartD' >"+
          "<h1 style= 'text-align:center;'><img src='res/info.svg' width='32' height='32'>&nbsp;&nbsp;&nbsp;Legend</h1>"+
          "<p style= 'font-weight: bold;'>"+
          "<span style='font-size:120%;'>WBI</span> - What we breathe index"+
          "<img src='./res/ballon_green.svg' width='22' height='22'><bg>"+
          "It shows the percentage of air quality in the displayed period"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "<br><span style='font-size:120%;'>SLI</span> - Sound level index"+
          "<img src='./res/ballon_green.svg' width='22' height='22'>"+
          "It shows the percentage of noise level in the displayed period"+
//          "<img src='./res/ballon_green.svg' width='22' height='22'><br></p>"+
//          "<table class='table' id='tbl1'>" +
//          "<tr><td style = 'font-weight: bold;'>WBI&nbsp&nbsp</td>"+
//          "<td style='background-color: #2eb72b;color: #445931; font-weight: bold;'>&nbsp0-25 µg/m<sup>3&nbsp</td>"+
//          "<td style='background-color: #e9e838;color: #445931; font-weight: bold;'>&nbsp20-30 µg/m<sup>3&nbsp</td>"+
//          "<td style='background-color: #e16527;color: #FFFFFF; font-weight: bold;'>&nbsp25-50 µg/m<sup>3&nbsp</td>"+
//          "<td style='background-color: #dd1f1d;color: #FFFFFF; font-weight: bold;'>&nbsp50-75 µg/m<sup>3&nbsp</td>"+
//          "<td style='background-color: #84223a;color: #FFFFFF; font-weight: bold;'>&nbsp75-800 µg/m<sup>3&nbsp</td>"+
//          "</tr>"+
//          "<tr><td style = 'font-weight: bold;'>SLI&nbsp&nbsp</td>"+
//          "<td style='background-color: #2eb72b;color: #445931;font-weight: bold;'>&nbsp&nbsp40-60 db&nbsp</td>"+
//          "<td style='background-color: #e9e838;color: #445931;font-weight: bold;'>&nbsp&nbsp60-80 db&nbsp</td>"+
//          "<td style='background-color: #e16527;color: #FFFFFF;font-weight: bold;'>&nbsp&nbsp80-90 db&nbsp</td>"+
//          "<td style='background-color: #dd1f1d;color: #FFFFFF;font-weight: bold;'>&nbsp&nbsp90-110 db&nbsp</td>"+
//          "<td style='background-color: #84223a;color: #FFFFFF;font-weight: bold;'>&nbsp&nbsp110-130 db&nbsp</td>"+
//          "</tr>"+
//          "</table>"+
        "</article>"
};