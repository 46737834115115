/******************************************************************************/
/* Copyright © 2022 bTEND Ltd. All right reserved.                            */
/******************************************************************************/
/******************************************************************************/ 
/*    Author     : Dimitar Krastev at d.krastev@btend.bg                      */
/******************************************************************************/
import {sensB} from './wb_controls.js';
import * as wb from './index.js';
import moment from 'moment/moment'

var bOrange = './res/ballon_orange.svg';
var bBlack = './res/ballon_black.svg';
var bBlue = './res/ballon_blue.svg';
var bGreen = './res/ballon_green.svg';
var bRed = './res/ballon_red.svg';
var bMagenta = './res/ballon_magenta.svg';
var bYellow = './res/ballon_yellow.svg';
var bCyan = './res/ballon_cyan.svg';
export var bPurple = './res/ballon_purple.svg';

var splGreen = '/res/spl_green.svg';
var splYellow = '/res/spl_yellow.svg';
var splOrange = '/res/spl_orange.svg';
var splRed = '/res/spl_red.svg';
var splMagenta = '/res/spl_magenta.svg';

var aqsensdata = [];
export var AQmarkers = new L.layerGroup();
/******************************************************************************/ 
/*    AQdata calculations                                                     */
/******************************************************************************/
function splTodb(spl){
    if ( Math.round(94+(20*Math.log10((Math.sqrt(spl)/1000)/2.24)))<40)
        return 40;
    else
        return Math.round(94+(20*Math.log10((Math.sqrt(spl)/1000)/2.24)));
}
function normHum(hum){
    if((Math.round(hum/1000))>99)
        return 99;
    else
        return Math.round(hum/1000);
}
function normTemp(temp,timeType){
    if (timeType === 'popup')
        return Number.parseFloat(temp/100).toFixed(1);
    else
        return temp/100;
}
function normBaro(baro){
    return Math.round(baro/100);
}
export function normAQdata(aqdata,timeType){
    aqdata.SPL = splTodb(aqdata.SPL);
    aqdata.Temperature = normTemp(aqdata.Temperature,timeType);
    aqdata.Humidity = normHum(aqdata.Humidity);
    aqdata.Barometer = normBaro(aqdata.Barometer);
    aqdata.PM1A = parseInt(aqdata.PM1A, 10);
    aqdata.PM2_5A = parseInt(aqdata.PM2_5A, 10);
    aqdata.PM10A = parseInt(aqdata.PM10A, 10);
    aqdata.BatteryLevel = parseInt(aqdata.BatteryLevel, 10);
    switch (timeType){
        case 'popup':
            var time=aqdata.time.split(' ')[1];
            var date=aqdata.time.split(' ')[0];
            time= time.slice(0,time.lastIndexOf(':')).concat('&nbsp&nbsp&nbsp');
            var datearr=date.split('-');
            date=datearr[2].concat('-').concat(datearr[1]).concat('-').concat(datearr[0]);
            aqdata.time=time.concat(date);
            break;
        case 'history':
            aqdata.time = moment(aqdata.time,'YYYY-MM-DD hh:mm:ss',false);
            break;
        case 'analyze':
            aqdata.time = moment(aqdata.time,'YYYY-MM-DD',false);
            break;
        default: 
    }
}
/******************************************************************************/ 
/*    AQmarker builders                                                       */
/******************************************************************************/
function wbIconMarker(aqdata){ 
    var iconURL,shadowURL;
    
    if(sensB._currentState.stateName==='pm10'){
    if (aqdata.PM10A<=50){iconURL=bGreen;
    }else{
        if (aqdata.PM10A<=75){iconURL=bYellow;
        }else{
            if (aqdata.PM10A<=100){iconURL=bOrange;
            }else{
                if (aqdata.PM10A<=150){iconURL=bRed;
                }else{
                    if (aqdata.PM10A>150){iconURL=bMagenta;
                    }
            }}}}
    shadowURL = '/res/ballon_shadow.svg';
    }
    if(sensB._currentState.stateName==='pm2'){
    if (aqdata.PM2_5A<=25){iconURL=bGreen;
    }else{
        if (aqdata.PM2_5A<=30){iconURL=bYellow;
        }else{
            if (aqdata.PM2_5A<=50){iconURL=bOrange;
            }else{
                if (aqdata.PM2_5A<=75){iconURL=bRed;
                }else{
                    if (aqdata.PM2_5A>75){iconURL=bMagenta;
                    }
            }}}}
    shadowURL = '/res/ballon_shadow.svg';
    }
    if(sensB._currentState.stateName==='spl'){
        if(aqdata.SPL<=60){iconURL=splGreen;
        }else{
            if(aqdata.SPL<=80){iconURL=splYellow;
            }else{
                if(aqdata.SPL<=90){iconURL=splOrange;
                }else{
                    if(aqdata.SPL<=110){iconURL=splRed;
                    }else{
                        if(aqdata.SPL<=130){iconURL=splMagenta;}
        }}}}
    shadowURL = '/res/spl_shadow.svg';    
    }
    if ((aqdata.aq_power.localeCompare("ON")===0)
            &&(aqdata.aq_onpwr.localeCompare('NO')===0)){
            iconURL=bCyan;
            shadowURL = '/res/spl_shadow.svg';
    }
    
return L.icon({
                iconUrl: iconURL,
                shadowUrl: shadowURL,
                iconSize:     [48, 48], // size of the icon
                shadowSize:   [48, 48], // size of the shadow
                iconAnchor:   [24,48], // point of the icon which will correspond to marker's location
                shadowAnchor: [32,36],  // the same for the shadow
                popupAnchor:  [0,-48] // point from which the popup should open relative to the iconAnchor
            });
}
function wbPopupMarker(aqdata){
    var level,charge;
    if(aqdata.BatteryLevel<=3250){
        level="empty";
    }else{
        if(aqdata.BatteryLevel<=3400){
               level="empty";
           }else{
               if(aqdata.BatteryLevel<=3500){
                   level="quarter";
               }else{
                   if(aqdata.BatteryLevel<=3700){
                        level="half";
                   }else{
                       if(aqdata.BatteryLevel<=3900){
                           level="three-quarters";
                       }else{
                           if(aqdata.BatteryLevel<=4400){
                               level="full";
                           }
                       }
                   }
               }
           }
       }
    if ((aqdata.aq_power.localeCompare("ON")===0)
            &&(aqdata.aq_onpwr.localeCompare('NO')===0)){
        if(aqdata.aq_charge.localeCompare("ON")===0){
            charge='fa fa-bolt fa-2x';
        } else {
            charge='';
        }
        aqdata.Temperature=0;
        aqdata.Humidity=0;
        aqdata.SPL=10;
        aqdata.Barometer=0;
        aqdata.PM1A=0;
        aqdata.PM2_5A=0;
        aqdata.PM10A=0;
    }else{
        charge="";
    }
    return ("<table class='table wb_table' id="+aqdata.id+">" +
            "<tr><td><i class='fa fa-clock-o  fa-2x'></i></td><td>"+aqdata.time+"</td></tr>"+
                "<tr><td><i class='fa fa-thermometer-three-quarters  fa-2x'></i></td><td >"+aqdata.Temperature+"&nbsp&nbsp°C</td></tr>"+
                "<tr><td><i class='fa fa-tint fa-2x'></i></td><td>"+aqdata.Humidity+"&nbsp&nbsp%</td></tr>"+
                "<tr><td><img src='/res/AirPressure.svg' style='width:26px;height:26px;'></td><td >"+aqdata.Barometer+"&nbsp&nbsphPa</td></tr>"+
                "<tr><td><i class='fa fa-volume-up fa-2x'></i></td><td >"+aqdata.SPL+"&nbsp&nbspdB</td></tr>"+
                "<tr><td><i class='fa fa-dot-circle-o fa-lg'></i>&nbsp&nbsp1µm</td><td style='text-align: right'>"+aqdata.PM1A+"&nbsp&nbspµg/m<sup>3 </td></tr>"+
                "<tr><td><i class='fa fa-dot-circle-o fa-lg'></i>&nbsp&nbsp2,5µm</td><td style='text-align: right'>"+aqdata.PM2_5A+"&nbsp&nbspµg/m<sup>3 </td></tr>"+
                "<tr><td><i class='fa fa-dot-circle-o fa-lg'></i>&nbsp&nbsp10µm</td><td style='text-align: right'>"+aqdata.PM10A+"&nbsp&nbspµg/m<sup>3 </td></tr>"+
          "</table>"+
          "<button class='popB' id='history' title='History'><img src='/res/Lineargraph.svg' style='width:30px;height:30px;'></i></button>"+
          "<button class='popB' id='analyze' title='Analyze'><img src='/res/Bargraph.svg' style='width:30px;height:30px;'></i></button>"+
          "<div class = 'batt' ><i class='fa fa-battery-"+level+" fa-2x'></i>"+"<i class='"+charge+"'></i></div>");
}

var getAQdata = function(aqsens){
    var ask; 
    let retdata;
    let aqmarker;
    ask = new XMLHttpRequest();
    ask.onreadystatechange = function() {
    if (this.readyState === 4 && this.status === 200) {
        try {
            retdata =JSON.parse(this.responseText);
            aqsensdata.push(retdata[0]);
            normAQdata(aqsensdata[aqsensdata.length-1],'popup');
            aqsensdata[aqsensdata.length-1]["latlng"]=L.latLng([aqsens.lat,aqsens.lng]);
            aqmarker = new L.marker(
                        aqsensdata[aqsensdata.length-1].latlng,
                           {icon:wbIconMarker(aqsensdata[aqsensdata.length-1]),
                            id:aqsensdata[aqsensdata.length-1].id
                        }).addTo(wb.map).bindPopup(wbPopupMarker(aqsensdata[aqsensdata.length-1])).bindTooltip("AQsens").bounce(2);
            AQmarkers.addLayer(aqmarker);
            aqmarker=null;
            }catch(e) {
                 // error 
            }
    }
  };
  ask.open("GET", "./php/aqsens_data.php?aqid="+aqsens.id,true);
  ask.send();
};
export var buildAQmarkers = function(){
    var wb_aqid;
    var aq_sens;
    aqsensdata.length = 0;
    AQmarkers.clearLayers();
    wb_aqid = new XMLHttpRequest();
    wb_aqid.onreadystatechange = function() {
        if (this.readyState === 4 && this.status === 200) {
            aq_sens = JSON.parse(this.responseText);
            aq_sens.forEach(getAQdata);
        }
    };
    wb_aqid.open("GET", "./php/aqsens_list.php", true);
    wb_aqid.send();
};

export function checkMarkerView(){
    var isvisible = false;
    var curentBounds = wb.map.getBounds();
    AQmarkers.eachLayer(function (layer){
        if(curentBounds.contains(layer.getLatLng())){
            isvisible = true;
        }
        });
    return isvisible;
}
/******************************************************************************/ 
/*    AQmarker updates                                                        */
/******************************************************************************/
function findAqSens(id) {
    var reti;
    aqsensdata.forEach(function(el,index){
        if(el.id===id) {
            reti = index;
        }
    });
    return reti;
}
export function updateAQmarkersView(){
    let popupView=false;
    let i;
    AQmarkers.eachLayer(function (layer){
        if(layer.isPopupOpen()){popupView=true;}
        i=findAqSens(layer.options.id);
        layer.stopBouncing(); 
        layer.unbindPopup();
        layer.setIcon(wbIconMarker(aqsensdata[i]));
        layer.bindPopup(wbPopupMarker(aqsensdata[i]));
        layer.bounce(1);
    });
    if(popupView){
    wb.map.fire('popupopen');
    }
}
export function updateAQmarkers(){
    let popupView=false;
    var ask; 
    let retdata; 
    AQmarkers.eachLayer(function (layer){
        if(layer.isPopupOpen()){popupView=true;}
            aqsensdata.length = 0;
            ask = new XMLHttpRequest();
            ask.onreadystatechange = function() {
                if (this.readyState === 4 && this.status === 200) {
                    retdata =JSON.parse(this.responseText);
                    aqsensdata.push(retdata[0]);
                    normAQdata(aqsensdata[aqsensdata.length-1],'popup');
                    layer.unbindPopup();
                    layer.setIcon(wbIconMarker(aqsensdata[aqsensdata.length-1]));
                    layer.bindPopup(wbPopupMarker(aqsensdata[aqsensdata.length-1]));
                }
            };
            ask.open("GET", "./php/aqsens_data.php?aqid="+layer.options.id,true);
            ask.send();
    });
    if(popupView){
    wb.map.fire('popupopen');
    }
}
/*****************************end of aqsens************************************/
